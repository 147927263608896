import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "body-width-reponsive-max pb-32" }
const _hoisted_2 = { class: "page-head-menu" }
const _hoisted_3 = { class: "page-title mr-auto" }
const _hoisted_4 = { class: "fs-12 fw-700" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = {
  key: 0,
  class: "box-card",
  style: {"background-color":"#fff"}
}
const _hoisted_7 = { class: "" }
const _hoisted_8 = {
  class: "flex-mid fs-18 fw-500",
  style: {"height":"50rem"}
}
const _hoisted_9 = { class: "center" }
const _hoisted_10 = { class: "fs-24 fw-700 fc-primary" }
const _hoisted_11 = { style: {"width":"243px","margin":"24px auto"} }
const _hoisted_12 = {
  class: "fs-12 mt-24",
  style: {"width":"424px","margin":"0 auto","color":"#696c80","font-size":"14px","line-height":"160%"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_popover = _resolveComponent("el-popover")!
  const _component_el_progress = _resolveComponent("el-progress")!
  const _component_my_assessments_table = _resolveComponent("my-assessments-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("assessments.My_assessments")), 1),
      _createVNode(_component_el_popover, {
        visible: _ctx.isShowedGuidedTourCreate,
        placement: "left",
        width: 220,
        "popper-class": "mc-popover"
      }, {
        reference: _withCtx(() => [
          _createElementVNode("div", {
            class: "ml-auto btn02 btn-48 btn-primary",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.create && _ctx.create(...args)))
          }, [
            _cache[1] || (_cache[1] = _createElementVNode("span", { class: "mr-10 fs-22 mb-3 fw-400" }, "+", -1)),
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t("assessments.New_Assessment")), 1)
          ])
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: "popover-content",
            innerHTML: _ctx.$t('guided_tour.create.step_0')
          }, null, 8, _hoisted_5)
        ]),
        _: 1
      }, 8, ["visible"])
    ]),
    _createElementVNode("div", null, [
      (_ctx.isGenerating)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t("assessments.We_re_generating_your_first_assessment")), 1),
                  _createElementVNode("div", _hoisted_11, [
                    _createVNode(_component_el_progress, {
                      percentage: _ctx.percentage,
                      "show-text": false,
                      color: _ctx.companyColor,
                      "stroke-width": 3
                    }, null, 8, ["percentage", "color"])
                  ]),
                  _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t(
                    "assessments.Our_AI_is_working_with_your_organization_type"
                  )), 1)
                ])
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      (!_ctx.isGenerating && _ctx.tableData)
        ? (_openBlock(), _createBlock(_component_my_assessments_table, { key: 1 }))
        : _createCommentVNode("", true)
    ])
  ]))
}