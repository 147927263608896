import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, createTextVNode as _createTextVNode } from "vue"
import _imports_0 from '@/assets/icons/white_arrow_right_24px.png'


const _hoisted_1 = { class: "menu-navbar-for-test" }
const _hoisted_2 = { class: "intro" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "timer-container" }
const _hoisted_6 = { class: "timer" }
const _hoisted_7 = { class: "time-label-wrapper" }
const _hoisted_8 = { class: "time" }
const _hoisted_9 = { class: "time-label" }
const _hoisted_10 = { class: "assessment-card-container" }
const _hoisted_11 = {
  key: 0,
  class: "assessment-content mb-32",
  style: {"min-height":"420px"}
}
const _hoisted_12 = {
  key: 0,
  class: "card-title"
}
const _hoisted_13 = {
  key: 1,
  class: "mt-12 card-description"
}
const _hoisted_14 = { class: "" }
const _hoisted_15 = { class: "scenario-value fc-primary mb-12" }
const _hoisted_16 = { class: "scenario-label mb-12" }
const _hoisted_17 = ["onClick"]
const _hoisted_18 = ["name", "checked"]
const _hoisted_19 = {
  key: 0,
  width: "14",
  height: "15",
  viewBox: "0 0 14 15",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_20 = ["stroke"]
const _hoisted_21 = {
  key: 1,
  width: "14",
  height: "14",
  viewBox: "0 0 14 14",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_22 = { class: "" }
const _hoisted_23 = {
  key: 1,
  class: "assessment-content mb-32"
}
const _hoisted_24 = { class: "width-max-520" }
const _hoisted_25 = { class: "fs-24 fw-900" }
const _hoisted_26 = { class: "card-description mt-12" }
const _hoisted_27 = { class: "flex-center mt-24" }
const _hoisted_28 = { class: "max-m-none mr-16" }
const _hoisted_29 = { class: "max-m-none mr-16" }
const _hoisted_30 = {
  key: 2,
  class: "assessment-content box-card mb-32"
}
const _hoisted_31 = { class: "width-max-520" }
const _hoisted_32 = { key: 0 }
const _hoisted_33 = { class: "card-title" }
const _hoisted_34 = { class: "card-description mt-12" }
const _hoisted_35 = { class: "fw-700" }
const _hoisted_36 = { class: "card-description mt-12" }
const _hoisted_37 = { class: "flex-mid" }
const _hoisted_38 = { key: 1 }
const _hoisted_39 = { class: "card-title" }
const _hoisted_40 = { class: "card-description mt-12" }
const _hoisted_41 = { class: "fw-700" }
const _hoisted_42 = { class: "card-description mt-16" }
const _hoisted_43 = { class: "flex-mid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_progress = _resolveComponent("el-progress")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["menu-container flex-center fixed", {
      'bottom-mobile': _ctx.step == 'Work_Style_Identifier',
    }])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("question.workStyleIdentifier.Work_Style_Identifier")), 1),
          _createElementVNode("div", {
            class: "desc",
            innerHTML: _ctx.nl2br(_ctx.$t('question.workStyleIdentifier.intro'))
          }, null, 8, _hoisted_4)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_el_progress, {
              type: "circle",
              class: "absolute-mid",
              "show-text": false,
              "stroke-linecap": "square",
              color: _ctx.companyColor,
              width: 128,
              height: 128,
              "stroke-width": 6,
              percentage: ((_ctx.percentageLabel - _ctx.timer) / _ctx.percentageLabel) * 100
            }, null, 8, ["color", "percentage"]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.timerLabel), 1),
              _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t("min_remaining")), 1)
            ])
          ])
        ])
      ])
    ], 2),
    _createElementVNode("div", _hoisted_10, [
      (
        (_ctx.step == 'introduce_Work_Style' || _ctx.step == 'Work_Style_Identifier') &&
        _ctx.workStyleScenarios &&
        _ctx.workStyleScenarios.scenarios
      )
        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.workStyleScenarios.scenarios, (scenario) => {
              return (_openBlock(), _createElementBlock(_Fragment, {
                key: scenario.id
              }, [
                (true || _ctx.scenarioIndexShowing == scenario.order)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      false
                        ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(_ctx.$t("question.workStyleIdentifier.Work_Style_Identifier")), 1))
                        : _createCommentVNode("", true),
                      false
                        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                            _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.$t(
                  "question.workStyleIdentifier.Based_on_your_natural_tendencies"
                )), 1)
                          ]))
                        : _createCommentVNode("", true),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(scenario.questions, (question) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: "scenario-container",
                          key: question.id
                        }, [
                          _createElementVNode("div", null, [
                            _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$t("question.workStyleIdentifier.Scenario")) + " " + _toDisplayString(scenario.order), 1),
                            _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.lang(scenario, "title")), 1)
                          ]),
                          _createElementVNode("div", null, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(question.options, (option) => {
                              return (_openBlock(), _createElementBlock("label", {
                                key: `${question.id}-${option.value}`,
                                class: _normalizeClass(["select-ab-btn flex-center color-primary", [{ active: option.checked }, _ctx.optionCls(option)]]),
                                onClick: ($event: any) => (_ctx.onClickOption(scenario, question, option))
                              }, [
                                _createElementVNode("input", {
                                  type: "radio",
                                  name: question.id,
                                  checked: option.checked
                                }, null, 8, _hoisted_18),
                                (option.checked)
                                  ? (_openBlock(), _createElementBlock("svg", _hoisted_19, [
                                      _createElementVNode("rect", {
                                        x: "2.25",
                                        y: "2.75",
                                        width: "9.5",
                                        height: "9.5",
                                        rx: "4.75",
                                        stroke: _ctx.companyColor,
                                        "stroke-width": "4.5"
                                      }, null, 8, _hoisted_20)
                                    ]))
                                  : (_openBlock(), _createElementBlock("svg", _hoisted_21, _cache[5] || (_cache[5] = [
                                      _createElementVNode("rect", {
                                        x: "0.7",
                                        y: "0.7",
                                        width: "12.6",
                                        height: "12.6",
                                        rx: "6.3",
                                        stroke: "#E6E8EC",
                                        "stroke-width": "1.4"
                                      }, null, -1)
                                    ]))),
                                _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.lang(option, "label")), 1)
                              ], 10, _hoisted_17))
                            }), 128))
                          ])
                        ]))
                      }), 128))
                    ], 64))
                  : _createCommentVNode("", true)
              ], 64))
            }), 128))
          ]))
        : (_ctx.step == 'opened_question')
          ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
              _createElementVNode("div", _hoisted_24, [
                _createElementVNode("div", _hoisted_25, _toDisplayString(_ctx.$t("assessmentTest.Anyz_feedback_or_thoughts")) + _toDisplayString(_ctx.$t("assessmentTest.work_style_identifier_q")), 1),
                _createElementVNode("div", _hoisted_26, _toDisplayString(_ctx.$t("assessmentTest.Optional")), 1),
                _withDirectives(_createElementVNode("textarea", {
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.openedQuestion) = $event)),
                  class: "textrarea-opened-question mt-16",
                  cols: "30",
                  rows: "10"
                }, null, 512), [
                  [_vModelText, _ctx.openedQuestion]
                ]),
                _createElementVNode("div", _hoisted_27, [
                  _createElementVNode("div", {
                    onClick: _cache[1] || (_cache[1] = ($event: any) => ((_ctx.skipOpenEndedQuestion = true), _ctx.finishOpenedQuestion())),
                    class: "ml-auto btn btn-50 btn-c-8D8D8D",
                    style: {"width":"14.4rem"}
                  }, [
                    _createElementVNode("span", _hoisted_28, _toDisplayString(_ctx.$t("btn.Skip")), 1)
                  ]),
                  _createElementVNode("div", {
                    class: _normalizeClass(["ml-auto btn02 btn-40 btn-primary ml-12", {
              'btn02-disable': !_ctx.openedQuestion,
            }]),
                    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.finishOpenedQuestion && _ctx.finishOpenedQuestion(...args))),
                    style: {"width":"100%"}
                  }, [
                    _createElementVNode("span", _hoisted_29, _toDisplayString(_ctx.$t("btn.Submit")), 1)
                  ], 2)
                ])
              ])
            ]))
          : (_ctx.step == 'complete')
            ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                _createElementVNode("div", _hoisted_31, [
                  (_ctx.lastSection)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
                        _createElementVNode("div", _hoisted_33, _toDisplayString(_ctx.$t("assessmentTest.You_ve_completed_this_section")), 1),
                        _createElementVNode("div", _hoisted_34, [
                          _createTextVNode(_toDisplayString(_ctx.$t("assessmentTest.Thank_you_for_taking_to_complete")) + " ", 1),
                          _createElementVNode("span", _hoisted_35, _toDisplayString(_ctx.$t("main.Work_Style_Identifier")), 1),
                          _createTextVNode(" " + _toDisplayString(_ctx.$t("assessmentTest.section_of_the_assessment")), 1)
                        ]),
                        _createElementVNode("div", _hoisted_36, _toDisplayString(_ctx.$t("assessmentTest.You_have_completed_all_sections")), 1),
                        _createElementVNode("div", _hoisted_37, [
                          _createElementVNode("div", {
                            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.finishAssessment && _ctx.finishAssessment(...args))),
                            class: "mx-auto btn02 btn-40 btn-primary",
                            style: {"margin-top":"8rem"}
                          }, [
                            _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Close")), 1)
                          ])
                        ])
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_38, [
                        _createElementVNode("div", _hoisted_39, _toDisplayString(_ctx.$t("assessmentTest.You_ve_completed_this_section")), 1),
                        _createElementVNode("div", _hoisted_40, [
                          _createTextVNode(_toDisplayString(_ctx.$t("assessmentTest.Thank_you_for_taking_to_complete")) + " ", 1),
                          _createElementVNode("span", _hoisted_41, _toDisplayString(_ctx.$t("main.Work_Style_Identifier")), 1),
                          _createTextVNode(" " + _toDisplayString(_ctx.$t("assessmentTest.section_of_the_assessment")), 1)
                        ]),
                        _createElementVNode("div", _hoisted_42, _toDisplayString(_ctx.$t("assessmentTest.Once_you_re_ready")), 1),
                        _createElementVNode("div", _hoisted_43, [
                          _createElementVNode("div", {
                            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.nextSectionBTN())),
                            class: "mx-auto btn02 btn-40 btn-primary",
                            style: {"margin-top":"8rem"}
                          }, [
                            _createElementVNode("span", null, _toDisplayString(_ctx.$t("assessmentTest.Go_to_the_Next_Section")), 1),
                            _cache[6] || (_cache[6] = _createElementVNode("img", {
                              src: _imports_0,
                              alt: "icons_white_arrow_right_24px",
                              class: "icon-24 ml-16 icon-invert-color"
                            }, null, -1))
                          ])
                        ])
                      ]))
                ])
              ]))
            : _createCommentVNode("", true)
    ])
  ], 64))
}