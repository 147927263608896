
import { defineComponent } from "vue";
// import { ElMessageBox } from "element-plus";
// import helpers from "@/helpers/global";
import MyAssessmentsTable from "@/components/Table/MyAssessments.vue";
// let loadingEl: any;

import _ from "lodash";

export default defineComponent({
  name: "Assessments",
  tableData: [],
  components: {
    MyAssessmentsTable,
  },
  async mounted() {
    if (!localStorage.getItem("userData")) {
      // this.$router.replace("/");
      return;
    }

    await this.$store.dispatch("user/loadCurrentUser", null, { root: true });
    let user = this.$store.getters["user/user"];

    if (!user.teamId) {
      await this.$store.dispatch("team/setup", user, {
        root: true,
      });
      await this.$store.dispatch("user/loadCurrentUser", null, { root: true });
      user = this.$store.getters["user/user"];
    }

    let hasTeam = false;
    let isPersonal = true;
    let team = null;
    if (user && user.teamId) {
      await this.$store.dispatch(
        "team/getTeam",
        { teamId: user.teamId },
        { root: true }
      );
      team = this.$store.getters["team/team"];
      if (team) {
        hasTeam = true;
        isPersonal = team.personal || false;
      }
    }
    this.isUserMode = !(hasTeam && !isPersonal);

    this.guidedTourCreateEnabled = _.get(
      user,
      "guidedTourCreateEnabled",
      false
    );
    this.guidedTourCreateDone = _.get(user, "guidedTourCreateDone", false);

    if (this.isUserMode && user.autoGenerateForm) {
      // @todo set teamId to this teamplate too
      await this.$store.dispatch("aiAssessmentTemplate/setup", user, {
        root: true,
      });
    } else {
      this.loadTables();
    }
  },
  data() {
    return {
      isUserMode: false,
      guidedTourCreateEnabled: false,
      guidedTourCreateDone: false,
      // tableData: [],
    };
  },
  watch: {
    finishedToGenerage(value) {
      if (value === true) {
        this.loadTables();
      }
    },
  },
  computed: {
    user(): any {
      return this.$store.getters["auth/user"] || {};
    },
    tableData() {
      return this.$store.getters["assessmentTemplate/items"];
    },
    isGenerating() {
      return this.$store.getters["aiAssessmentTemplate/locked"];
    },
    percentage(): any {
      return this.$store.getters["aiAssessmentTemplate/percentage"];
    },
    finishedToGenerage() {
      return this.$store.getters["aiAssessmentTemplate/finished"];
    },
    companyColor(): string {
      const defaultCompanyColor = "#1B1C1E";
      // const user = this.$store.getters["user/user"];
      const userRoute = this.$store.getters["routes/user"];

      const path = this.isUserMode ? "user/user" : "team/team";
      const user: any = this.$store.getters[path] || {};
      if (user && user.color) {
        return user.color;
      } else if (userRoute && userRoute.color) {
        return userRoute.color;
      }
      return defaultCompanyColor;
    },
    isShowedGuidedTourCreate() {
      return this.guidedTourCreateEnabled && !this.guidedTourCreateDone;
    },
  },
  methods: {
    loadTables() {
      this.$store.dispatch(
        "assessmentTemplate/getList",
        { page: 1 },
        { root: true }
      );
    },
    async create() {
      const callback = () => {
        this.$router.push({
          name: "AssessmentsCreate",
          params: { step: "name" },
        });
      };
      await this.$store.dispatch(
        "assessmentTemplate/cleanup",
        { callback },
        {
          root: true,
        }
      );
    },
  },
});
