import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"
import _imports_0 from '@/assets/icons/navigate_right_black_24px.png'


const _hoisted_1 = { class: "menu-container flex-center" }
const _hoisted_2 = { class: "body-width-reponsive-max" }
const _hoisted_3 = { class: "nav-bar-container flex-center" }
const _hoisted_4 = { class: "flex-center" }
const _hoisted_5 = { class: "label-container" }
const _hoisted_6 = { class: "nav-text-header ellipsis" }
const _hoisted_7 = { class: "nav-text-description" }
const _hoisted_8 = { class: "ml-auto flex-center" }
const _hoisted_9 = ["fill"]
const _hoisted_10 = { class: "ml-12" }
const _hoisted_11 = {
  key: 0,
  class: "ml-12"
}
const _hoisted_12 = {
  key: 1,
  class: "ml-12"
}
const _hoisted_13 = {
  class: "body-width",
  style: {"margin-top":"6.4rem"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_main_card_reults = _resolveComponent("main-card-reults")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.go(-1))),
              class: "btn-left"
            }, _cache[3] || (_cache[3] = [
              _createElementVNode("img", {
                src: _imports_0,
                alt: "icons_navigate_right_black_24px",
                class: "icon-16 animetion",
                style: {"transform":"rotate(180deg)"}
              }, null, -1)
            ])),
            _createElementVNode("div", _hoisted_5, [
              (_ctx.data && _ctx.data.form_name && _ctx.data.firstname && _ctx.data.lastname)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.data.form_name) + " - " + _toDisplayString(_ctx.data.firstname) + " " + _toDisplayString(_ctx.data.lastname), 1),
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.dateFormat(_ctx.data.finished_at)), 1),
                      _cache[4] || (_cache[4] = _createTextVNode(" • ")),
                      _createElementVNode("span", null, _toDisplayString(_ctx.data.email), 1)
                    ])
                  ], 64))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _cache[5] || (_cache[5] = _createElementVNode("div", { class: "nav-text-header ellipsis" }, " Loading assessment details... ", -1)),
                    _cache[6] || (_cache[6] = _createElementVNode("div", { class: "nav-text-description" }, [
                      _createElementVNode("span", null, "This may take a moment")
                    ], -1))
                  ], 64))
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            (_ctx.data && _ctx.data.form_name && _ctx.data.firstname && _ctx.data.lastname)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createElementVNode("div", {
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.coppyLink())),
                    class: "btn02-outline btn-40"
                  }, [
                    (_openBlock(), _createElementBlock("svg", {
                      xmlns: "http://www.w3.org/2000/svg",
                      height: "24px",
                      viewBox: "0 0 24 24",
                      width: "24px",
                      fill: _ctx.companyColor
                    }, _cache[7] || (_cache[7] = [
                      _createElementVNode("path", {
                        d: "M0 0h24v24H0V0z",
                        fill: "none"
                      }, null, -1),
                      _createElementVNode("path", { d: "M17 7h-4v2h4c1.65 0 3 1.35 3 3s-1.35 3-3 3h-4v2h4c2.76 0 5-2.24 5-5s-2.24-5-5-5zm-6 8H7c-1.65 0-3-1.35-3-3s1.35-3 3-3h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-2zm-3-4h8v2H8z" }, null, -1)
                    ]), 8, _hoisted_9)),
                    _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t("assessmentResults.COPY_LINK")), 1)
                  ]),
                  _createElementVNode("div", {
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.sendEmailResults(_ctx.data))),
                    class: "ml-20 btn02 btn-40 btn-primary",
                    style: {"text-transform":"uppercase"}
                  }, [
                    _cache[8] || (_cache[8] = _createElementVNode("svg", {
                      xmlns: "http://www.w3.org/2000/svg",
                      height: "24px",
                      viewBox: "0 0 24 24",
                      width: "24px",
                      fill: "#fff"
                    }, [
                      _createElementVNode("path", {
                        d: "M0 0h24v24H0V0z",
                        fill: "none"
                      }),
                      _createElementVNode("path", { d: "M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6zm-2 0l-8 5-8-5h16zm0 12H4V8l8 5 8-5v10z" })
                    ], -1)),
                    (_ctx.formTemplate && !_ctx.formTemplate.auto_send_result)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_ctx.$t("assessmentResults.SEND_RESULTS_TO")) + " " + _toDisplayString(_ctx.data.firstname), 1))
                      : (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.$t("assessmentResults.RE_SEND_RESULTS_TO")) + " " + _toDisplayString(_ctx.data.firstname), 1))
                  ])
                ], 64))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [], 64))
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_13, [
      _createVNode(_component_main_card_reults)
    ])
  ], 64))
}