
import { defineComponent } from "vue";
import helpers from "@/helpers/global";
import { ElMessageBox } from "element-plus";
// import CustomSelect from "@/components/Input/SelectBox.vue";
import Pagination from "@/components/Data/Pagination.vue";
// import Loading from "../Basic/Loading.vue";

import _ from "lodash";

interface dataTable {
  _id: string;
  form_name: string;
  candidate_total: number;
  progress: number;
  last_activity_at: string;
  published_at: string;
  routeId: string;
}

export default defineComponent({
  name: "MyAssessments",
  components: {
    // CustomSelect,
    Pagination,
    // Loading,
  },
  async mounted() {
    // Get teams agian becuase user may be realod page
    await this.$store.dispatch("user/loadCurrentUser", null, { root: true });
    await this.$store.dispatch("team/getTeams", null, { root: true });
    const teams = this.$store.getters["team/teams"];
    this.noTeam = teams && teams.length < 2;

    let user = this.$store.getters["user/user"];
    let hasTeam = false;
    let isPersonal = true;
    let team = null;
    if (user && user.teamId) {
      await this.$store.dispatch(
        "team/getTeam",
        { teamId: user.teamId },
        { root: true }
      );
      team = this.$store.getters["team/team"];
      if (team) {
        hasTeam = true;
        isPersonal = team.personal || false;
      }
    }
    this.isUserMode = !(hasTeam && !isPersonal);

    this.guidedTourCreateDone = _.get(user, "guidedTourCreateDone", false);
    this.guidedTourInviteDone = _.get(user, "guidedTourInviteDone", false);
  },
  computed: {
    dataFiltered(): any {
      const fillter = (this as any).fillterValue || "";
      // const data = JSON.parse(JSON.stringify(this.tableData));
      const data = this.tableData;
      const dataSorted: dataTable[] = (this as any).sortData(data, fillter);
      const dataSearched: dataTable[] = dataSorted.filter((item: any) => {
        return (
          item.form_name
            .toLowerCase()
            .includes(this.searchInput.toLowerCase()) ||
          item.candidate_total
            .toString()
            .toLowerCase()
            .includes(this.searchInput.toLowerCase())
        );
      });
      return dataSearched || [];
    },
    tableSliceIndex() {
      const dataSlice: dataTable[] = (this.dataFiltered as any).slice(
        (this as any).dataIndexShowing[0] - 1,
        (this as any).dataIndexShowing[1]
      );
      return dataSlice || [];
    },
    tableData(): any[] {
      return this.$store.getters["assessmentTemplate/items"];
    },
    loading(): boolean {
      return this.$store.getters["assessmentTemplate/loading"];
    },
  },
  watch: {
    tableData() {
      this.resetPagination();
    },
    searchInput() {
      this.resetPagination();
    },
    fillterValue() {
      this.resetPagination();
    },
  },
  data() {
    return {
      noTeam: true,
      isUserMode: false,
      guidedTourCreateDone: false,
      guidedTourInviteDone: false,
      fillterValue: "",
      searchInput: "",
      selectedAssessment: {
        label: "",
        key: "",
      },
      page: 1,
      dataIndexShowing: [1, 10],
      assessmentsOptional: [
        {
          label: "As_AAAA",
          key: "As_AAAA",
        },
        {
          label: "As_BBB",
          key: "As_BBB",
        },
      ],
      selectedStatus: {
        label: "",
        key: "",
      },
      statusOptional: [
        {
          label: "Status_AAAA",
          key: "Status_AAAA",
        },
        {
          label: "Status_BBB",
          key: "Status_BBB",
        },
      ],
    };
  },
  methods: {
    isCreatedByGuidedTour(data: any): boolean {
      return _.get(data, "is_created_by_guided_tour", false);
    },
    isAllowedToShowInvitePopover(data: any) {
      let allowed = this.guidedTourCreateDone && !this.guidedTourInviteDone;
      if (allowed) {
        allowed = this.isCreatedByGuidedTour(data);
      }
      return allowed;
    },
    canEdit(data: any): boolean {
      if (data && this.$store.getters["auth/user"]) {
        return data.creator == this.$store.getters["auth/user"].email;
      }
      return false;
    },
    isPrivate(data: any): boolean {
      if (this.isUserMode) return false;

      return data && data.scope == "personal";
    },
    resetPagination() {
      this.page = 1;
      this.dataIndexShowing = [1, 10];
    },
    handlePageChange(page: any) {
      this.page = page.currentPage;
      this.dataIndexShowing = page.dataIndex;
    },
    handleAssessmentInput(newValue: any) {
      this.resetPagination();
      this.selectedAssessment = newValue;
    },
    handleStatusInput(newValue: any) {
      console.log(newValue);
      this.resetPagination();
      this.selectedStatus = newValue;
    },
    filterActiveClass(value: string) {
      if (this.fillterValue == value) {
        return "active";
      }
      return "";
    },
    barWidht(progress: number, candidates: number) {
      const pergressPercentage = (progress / candidates) * 100;
      return `width: ${pergressPercentage.toFixed(1)}%`;
    },
    // fontColor(progress: number, candidates: number) {
    //   const pergressPercentage = (progress / candidates) * 100;
    //   if (+pergressPercentage.toFixed(1) > 50) {
    //     return "fc-FFFFFF";
    //   }
    //   return "text-shadow";
    // },
    getLocaleDate(time: string) {
      return helpers.getLocaleDate(time);
    },
    timeAgo(time: string) {
      return helpers.timeAgo(time);
    },
    async handleCommand(command: string, row: any, event?: any) {
      const routeId = row.routeId;
      switch (command) {
        case "edit":
          if (!this.canEdit(row)) {
            console.log("can't edit");
            event.preventDefault();
            return;
          }
          this.$router.replace(`/assessment/edit/${row.routeId}`);
          break;
        case "clone":
          await this.$store.dispatch(
            "assessmentTemplate/clone",
            {
              routeId: routeId,
            },
            { root: true }
          );
          this.loadTables();
          break;
        case "delete":
          if (!this.canEdit(row)) {
            console.log("can't delete");
            event.preventDefault();
            return;
          }
          ElMessageBox.confirm(
            (this as any).$t("popup.question.question_box_2.title"),
            (this as any).$t("popup.question.question_box_2.question"),
            {
              confirmButtonText: (this as any).$t("popup.optional.Yes_chi"),
              cancelButtonText: (this as any).$t("popup.optional.Cancel"),
              type: "warning",
            }
          )
            .then(async () => {
              await this.$store.dispatch("assessmentTemplate/softDelete", row, {
                root: true,
              });
              this.loadTables();
            })
            .catch((error) => {
              if (error == "cancel") return;
              console.error(error);
            });
      }
    },
    loadTables() {
      this.$store.dispatch(
        "assessmentTemplate/getList",
        { page: 1 },
        { root: true }
      );
    },
    sortData(data: any, fillter: string) {
      if (fillter == "name_ascending") {
        return data.sort((a: any, b: any) =>
          a.form_name > b.form_name ? 1 : b.form_name > a.form_name ? -1 : 0
        );
      } else if (fillter == "name_descending") {
        return data.sort((a: any, b: any) =>
          a.form_name < b.form_name ? 1 : b.form_name < a.form_name ? -1 : 0
        );
      } else if (fillter == "candidate_ascending") {
        return data.sort((a: any, b: any) =>
          a.candidate_total > b.candidate_total
            ? 1
            : b.candidate_total > a.candidate_total
            ? -1
            : 0
        );
      } else if (fillter == "candidate_descending") {
        return data.sort((a: any, b: any) =>
          a.candidate_total < b.candidate_total
            ? 1
            : b.candidate_total < a.candidate_total
            ? -1
            : 0
        );
      } else if (fillter == "progress_ascending") {
        return data.sort((a: any, b: any) =>
          a.progress > b.progress ? 1 : b.progress > a.progress ? -1 : 0
        );
      } else if (fillter == "progress_descending") {
        return data.sort((a: any, b: any) =>
          a.progress < b.progress ? 1 : b.progress < a.progress ? -1 : 0
        );
      } else if (fillter == "last_activity_ascending") {
        return data.sort((a: any, b: any) =>
          a.last_activity_at > b.last_activity_at
            ? 1
            : b.last_activity_at > a.last_activity_at
            ? -1
            : 0
        );
      } else if (fillter == "last_activity_descending") {
        return data.sort((a: any, b: any) =>
          a.last_activity_at < b.last_activity_at
            ? 1
            : b.last_activity_at < a.last_activity_at
            ? -1
            : 0
        );
      } else if (fillter == "date_created_ascending") {
        return data.sort((a: any, b: any) =>
          a.published_at > b.published_at
            ? 1
            : b.published_at > a.published_at
            ? -1
            : 0
        );
      } else if (fillter == "date_created_descending") {
        return data.sort((a: any, b: any) =>
          a.published_at < b.published_at
            ? 1
            : b.published_at < a.published_at
            ? -1
            : 0
        );
      } else {
        return data || [];
      }
    },
    async create() {
      const callback = () => {
        this.$router.push({
          name: "AssessmentsCreate",
          params: { step: "name" },
        });
      };
      await this.$store.dispatch(
        "assessmentTemplate/cleanup",
        { callback },
        {
          root: true,
        }
      );
    },
  },
});
