
import { defineComponent } from "vue";
import { ElMessage } from "element-plus";
import helpers from "@/helpers/global";
import settings from "@/helpers/settings";
import _ from "lodash";
// let loadingEl: any;

export default defineComponent({
  name: "Candidate",
  async created() {
    if (!localStorage.getItem("userData")) {
      this.$router.replace("/");
      return;
    }

    this.sectionListStepTwo = [];

    this.sectionListStepTwo.push({
      title: "Values_Alignment",
      description: [{ class: "", label: "Values_Alignment_Description" }],
      description_end: "Determine_value_alignment",
      timeDefault: settings.VALUES_ALIGNMENT_TIME,
      keyValue: "values_and_traits",
      modelType: helpers.t("create.Culture_Specific"),
    });
    this.sectionListStepTwo.push({
      title: "Culture_Profile",
      description: [
        { class: "", label: "Organizational_culture_is_driven" },
        { class: "fw-700", label: "Clan_Create_Collaborate_and" },
        { class: "", label: "across_six_dimensions_of" },
      ],
      description_end: "Determine_culture_profile",
      timeDefault: settings.CULTURE_PROFILE_TIME,
      keyValue: "OAIC_archetypes",
      modelType: helpers.t("create.Culture_Specific"),
    });
    if (this.HUMAN_SKILLS_ENABLED) {
      this.sectionListStepTwo.push({
        title: "Human_Skills",
        description: [{ class: "", label: "Human_skills_soft_skills" }],
        description_end: "Evaluate_human_skills",
        timeDefault: settings.HUMAN_SKILLS_TIME,
        keyValue: "human_skills",
        modelType: helpers.t("create.General"),
      });
    }
    this.sectionListStepTwo.push({
      title: "Acceptable_Behaviors",
      description: [{ class: "", label: "Behaviors_are_considered_toxic" }],
      description_end: "Determine_expected_behaviors_and",
      timeDefault: settings.ACCEPTABLE_BEHAVIORS_TIME,
      keyValue: "acceptable_behaviors",
      modelType: helpers.t("create.Culture_Specific"),
    });
    this.sectionListStepTwo.push({
      title: "Work_Style_Identifier",
      description: [
        { class: "", label: "Identify_a_candidate_work_style" },
        { class: "fw-700", label: "Product_Process_or_People" },
        { class: "", label: "Understanding_the_inclination_for" },
      ],
      description_end: "",
      timeDefault: settings.WORK_STYLE_IDENTIFIER_TIME,
      keyValue: "work_style_identifier",
      modelType: helpers.t("create.General"),
    });
    // Logic Test
    this.sectionListStepTwo.push({
      title: "Logic_Test",
      description: [
        { class: "", label: "Logical_reasoning_is_key_to_problem_solving" },
      ],
      description_end: "Determine_logical_reasoning_proficiency",
      timeDefault: settings.LOGIC_TEST_TIME,
      keyValue: "logic_test",
      modelType: helpers.t("create.General"),
    });
    // Custom Survey card add assessment to edit tab
    this.sectionListStepTwo.push({
      title: "Custom_Survey",
      description: [{ class: "", label: "Custom_Survey_Description" }],
      description_end: "Custom_Survey_Description_Footer",
      timeDefault: settings.CUSTOM_SURVEY_TIME,
      keyValue: "custom_survey",
      modelType: helpers.t("create.Culture_Specific"),
    });

    const params = this.$router.currentRoute.value.params;
    const routeId = params.routeId || "";
    this.routeId = routeId;
    let isNew = routeId.length == 0;

    let doc;
    if (isNew) {
      let initFormDetails;
      if (this.RELOAD_TO_EDIT_ENABLED) {
        await this.$store.dispatch("assessmentTemplate/getInitFormDetails");
        initFormDetails =
          this.$store.getters["assessmentTemplate/initFormDetails"];
      }
      // console.log(initFormDetails, "initFormDetails");
      doc = await this.$store.dispatch(
        "assessmentTemplate/new",
        initFormDetails
      );
    } else {
      doc = await this.$store.dispatch(
        "assessmentTemplate/edit",
        {
          routeId: routeId,
        },
        { root: true }
      );
    }

    if (this.$store.getters["assessmentTemplate/error"]) {
      ElMessage.error(this.$store.getters["assessmentTemplate/error"]);
      console.error(this.$store.getters["assessmentTemplate/error"]);
      return;
    }

    if (!doc) {
      console.error("doc is undefiend");
      return;
    }

    const values = doc.val();
    this.$store.commit("aiAssessmentTemplate/tempFormData", values);

    this.form.name = values.form_name;
    this.form.intendedfor = values.objective;
    this.form.scope = values.scope || "personal";
    const sections = values.sections || [];
    if (Array.isArray(values.languages_required)) {
      for (const i in values.languages_required) {
        this.form.langRequired.push(values.languages_required[i]);
      }
    }

    this.form.version = _.get(values, "version", 2.0);
    if (this.form.version >= 2.1) {
      this.isEnabledInputForAi = true;
      this.form.roleSelected = _.get(values, "role", "");
      this.form.typeOfOrgSelected = _.get(values, "type_of_org", "");
      this.form.coreValuesSelected = _.get(values, "core_values", []);
    }

    // advanced options
    if (isNew) {
      this.form.extraTime = "";
      this.form.sharingResults = false;
      this.form.openEndedQuestion = true;
    } else {
      this.form.extraTime = values.extra_time || "";
      this.form.sharingResults = values.auto_send_result || false;
      this.form.openEndedQuestion = values.open_ended_question || false;

      // console.log(values, "values");
      if (values.redirect_url) {
        this.form.redirectUrl = values.redirect_url;
        this.redirectUrl = this.form.redirectUrl;
      }
    }

    // console.log(isNew, "isNew");
    // console.log(this.formId, "this.formId");

    // flags for edit mode
    this.isNew = isNew;
    this.isStartedTest = values.is_started_test || false;
    this.canEdit = !this.isStartedTest;

    // @fixed reload `select` step
    const curStep = _.get(params, "step");
    if (isNew && (curStep == "select" || curStep == "review")) {
      isNew = false;
    }

    // @fixed
    // this.isStartedTest = false;

    if (isNew) {
      // Don't auto select when first time to create form
      /*
      for (const i in sections) {
        this._onClickSection(sections[i]);
      }
      //*/
    } else {
      this.form.sections.values_and_traits = false;
      this.form.sections.OAIC_archetypes = false;
      this.form.sections.human_skills = false;
      this.form.sections.acceptable_behaviors = false;
      this.form.sections.work_style_identifier = false;
      this.form.sections.logic_test = false;
      this.form.sections.custom_survey = false;
      for (const i in sections) {
        this._onClickSection(sections[i]);
      }
    }

    if (!this.HUMAN_SKILLS_ENABLED) {
      this.form.sections.human_skills = false;
    }

    const sectionNames = [
      "values_and_traits",
      "oaic_archetypes",
      "human_skills",
      "acceptable_behaviors",
      "work_style_identifier",
      "logic_test",
      "custom_survey",
    ];
    for (const key in sectionNames) {
      const sectionName = sectionNames[key].toLowerCase();
      if (Object.hasOwnProperty.call(values, sectionName)) {
        if (sectionName == "values_and_traits") {
          this.form.setupSections.values_and_traits = true;
        }
        if (sectionName == "oaic_archetypes") {
          this.form.setupSections.OAIC_archetypes = true;
        }
        if (sectionName == "human_skills") {
          this.form.setupSections.human_skills = true;
        }
        if (sectionName == "acceptable_behaviors") {
          this.form.setupSections.acceptable_behaviors = true;
        }
        if (sectionName == "work_style_identifier") {
          this.form.setupSections.work_style_identifier = true;
        }
        if (sectionName == "logic_test") {
          this.form.setupSections.logic_test = true;
        }
        if (sectionName == "custom_survey") {
          this.form.setupSections.custom_survey = true;
        }
      }
    }

    const step = String(this.$router.currentRoute.value.params.step);

    // reduce step 4 to 3
    if (step == "setup") {
      this.$router.push({
        name: "AssessmentsCreate",
        params: { step: "select" },
      });
      return;
    }

    const pos = this.stepNames.indexOf(step);
    this.step = pos !== -1 ? pos + 1 : 1;

    if (this.step == 3) {
      if (
        this.form.sections.values_and_traits ||
        this.form.sections.OAIC_archetypes
      ) {
        if (this.AI_ASSISTED_SETUP) {
          await this.$store.dispatch(
            "aiAssistedSetup/initDefaultCoreValues",
            null,
            {
              root: true,
            }
          );
          const defaultCoreValues =
            this.$store.getters["aiAssistedSetup/defaultCoreValues"] || [];
          for (const i in defaultCoreValues) {
            this.form.coreValue.push(defaultCoreValues[i]);
          }
        }
      }
    }
  },
  async mounted() {
    if (!this.$store.getters["user/user"]) {
      await this.$store.dispatch("user/loadCurrentUser");
    }
    await this.$store.dispatch("team/getTeams", null, { root: true });
    const teams = this.$store.getters["team/teams"];
    this.noTeam = teams && teams.length < 2;

    let user = this.$store.getters["user/user"];
    let hasTeam = false;
    let isPersonal = true;
    let team = null;
    if (user && user.teamId) {
      await this.$store.dispatch(
        "team/getTeam",
        { teamId: user.teamId },
        { root: true }
      );
      team = this.$store.getters["team/team"];
      if (team) {
        hasTeam = true;
        isPersonal = team.personal || false;
      }
    }
    this.isUserMode = !(hasTeam && !isPersonal);

    await this.$store.dispatch("assessmentSection/initValuesAndTraits", null, {
      root: true,
    });
    await this.$store.dispatch(
      "assessmentSection/loadSetupValuesAndTraits",
      null,
      {
        root: true,
      }
    );

    this.guidedTourCreateEnabled = _.get(
      user,
      "guidedTourCreateEnabled",
      false
    );
    this.guidedTourCreateDone = _.get(user, "guidedTourCreateDone", false);
  },
  watch: {
    tempFormData: {
      handler() {
        this.setupByAIStatus.values_and_traits = _.get(
          this.tempFormData,
          "values_and_traits_generated_by_ai",
          false
        );
        this.setupByAILoadingStatus.values_and_traits = false;

        this.setupByAIStatus.OAIC_archetypes = _.get(
          this.tempFormData,
          "oaic_archetypes_generated_by_ai",
          false
        );
        this.setupByAILoadingStatus.OAIC_archetypes = false;

        this.setupByAIStatus.acceptable_behaviors = _.get(
          this.tempFormData,
          "acceptable_behaviors_generated_by_ai",
          false
        );
        this.setupByAILoadingStatus.acceptable_behaviors = false;

        // force remove ui loading
        this.aiLoading = false;
      },
      deep: true,
    },
    error(value) {
      console.error(value);
    },
    // loading(value) {
    //   if (value) {
    //     loadingEl = ElLoading.service({
    //       lock: true,
    //       text: "Login..",
    //       background: "#ffffff90",
    //     });
    //   } else {
    //     loadingEl.close();
    //   }
    // },
    valuesAndTraitsLoading(value) {
      // console.log(`valuesAndTraitsLoading(${value})`);
      this.setupByAILoadingStatus.values_and_traits = value;
      if (value) {
        this.setupByAIStatus.values_and_traits = true;
      }
    },
    cultureProfileLoading(value) {
      // console.log(`cultureProfileLoading(${value})`);
      this.setupByAILoadingStatus.OAIC_archetypes = value;
      if (value) {
        this.setupByAIStatus.OAIC_archetypes = true;
      }
    },
    aiAssessmentTemplateFinished(value) {
      if (value) {
        this.afterGenerateSubformsByAi();
      }
    },
  },
  computed: {
    roleOptions() {
      return this.$store.getters["toolbox/roleOptions"];
    },
    typeOfOrgOptions() {
      return this.$store.getters["toolbox/typeOfOrgOptions"];
    },
    valuesAndTraits() {
      const valuesAndTraits =
        this.$store.getters["assessmentSection/valuesAndTraits"];
      if (
        valuesAndTraits &&
        valuesAndTraits.scenarios &&
        valuesAndTraits.scenarios.definingYourValues &&
        valuesAndTraits.scenarios.definingYourValues.questions
      ) {
        // helpers.shuffleArray(
        //   valuesAndTraits.scenarios.definingYourValues.questions
        // );
        valuesAndTraits.scenarios.definingYourValues.questions.sort(
          (a: any, b: any) => {
            const nameA = a.title.toUpperCase(); // ignore upper and lowercase
            const nameB = b.title.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }

            // names must be equal
            return 0;
          }
        );
      }
      return valuesAndTraits;
    },
    isFirstStep(): boolean {
      return this.step == 1;
    },
    isLastStep(): boolean {
      return this.step == 4;
    },
    canPreviousStep(): boolean {
      return true;
    },
    canNextStep(): boolean {
      switch (this.step) {
        case 1:
          return this.btnNexstepOne;
        case 2:
        case 3:
          return this.btnNexstepTwo && this.btnNexstepThree;
      }
      return true;
    },
    redirectUrlName(): string {
      // this.form.redirectUrl = "";
      const url = this.form.redirectUrl;
      if (
        url == "" ||
        url == "https://myculture.ai/thank-you-for-submitting" ||
        url == "https://myculture.ai/thank-you-for-submitting/"
      )
        return helpers.t("create.Default_Thank_You_Page");
      else return helpers.t("create.Custom_Page");
    },
    isEmptySections(): boolean {
      let found = false;
      const sections: any = this.form.sections;
      for (const i in sections) {
        if (sections[i]) {
          found = true;
          break;
        }
      }
      return !found;
    },
    yourSelectedItemsTotal(): number {
      let total = 0;
      this.form.sections.values_and_traits && total++;
      this.form.sections.OAIC_archetypes && total++;
      this.form.sections.human_skills && total++;
      this.form.sections.acceptable_behaviors && total++;
      this.form.sections.work_style_identifier && total++;
      this.form.sections.logic_test && total++;
      this.form.sections.custom_survey && total++;
      return total;
    },
    yourSectionsIsRequired(): boolean {
      if (this.form.sections.values_and_traits && !this.hasValuesAndTraits)
        return true;
      if (this.form.sections.OAIC_archetypes && !this.hasOaicArchetypes)
        return true;
      if (this.form.sections.custom_survey && !this.hasCustomSurvey)
        return true;
      if (
        this.form.sections.acceptable_behaviors &&
        !this.hasAcceptableBehaviors
      )
        return true;
      return false;
    },
    langRequiredLabel(): string {
      return this.form.langRequired.join(", ");
    },
    langSections(): string {
      const labels = [];
      if (this.form.sections.values_and_traits)
        labels.push(helpers.t("create.Values_Alignment"));
      if (this.form.sections.OAIC_archetypes)
        labels.push(helpers.t("create.Culture_Profile"));
      if (this.form.sections.acceptable_behaviors)
        labels.push(helpers.t("create.Acceptable_Behaviors"));
      if (this.form.sections.human_skills)
        labels.push(helpers.t("create.Human_Skills"));
      if (this.form.sections.work_style_identifier)
        labels.push(helpers.t("create.Work_Style_Identifier"));
      if (this.form.sections.logic_test)
        labels.push(helpers.t("create.Logic_Test"));
      if (this.form.sections.custom_survey)
        labels.push(helpers.t("create.Custom_Survey"));
      return labels.join(", ");
    },
    extraTimeAdd() {
      let extime = "";
      extime = this.form.extraTime;
      return Number(extime);
    },
    btnNexstepOne() {
      // console.log(this.form, "this.form");
      if (this.form.name && this.form.intendedfor) {
        if (this.LANGUAGES_REQUIRED_ENABLED) {
          if (!this.form.langRequired.length) return false;
        }
        if (this.isEnabledInputForAi) {
          if (!this.form.typeOfOrgSelected) return false;
          if (!this.form.coreValuesSelected.length) return false;
        }
        return true;
      } else {
        return false;
      }
    },
    btnNexstepTwo() {
      const value =
        this.form.sections.values_and_traits ||
        this.form.sections.OAIC_archetypes ||
        this.form.sections.human_skills ||
        this.form.sections.acceptable_behaviors ||
        this.form.sections.work_style_identifier ||
        this.form.sections.logic_test ||
        this.form.sections.custom_survey;
      if (value) {
        return true;
      } else {
        return false;
      }
    },
    btnNexstepThree() {
      let validate = true;
      if (this.form.sections.values_and_traits) {
        if (!this.hasValuesAndTraits) {
          validate = false;
        }
      }
      if (this.form.sections.OAIC_archetypes) {
        if (!this.hasOaicArchetypes) {
          validate = false;
        }
      }
      if (this.form.sections.acceptable_behaviors) {
        if (!this.hasAcceptableBehaviors) {
          validate = false;
        }
      }
      if (this.form.sections.custom_survey) {
        if (!this.hasCustomSurvey) {
          validate = false;
        }
      }
      return validate;
    },
    existsTemplates(): any[] {
      const templates =
        this.$store.getters["assessmentTemplate/existsTemplates"];
      // console.log(templates, "templates");
      // templates.unshift({
      //   _id: "",
      //   form_name: helpers.t("setup._Use_the_same_setup"),
      // });
      return templates;
    },
    formId(): any {
      return this.$store.getters["assessmentTemplate/formId"];
    },
    values(): any {
      return this.$store.getters["assessmentTemplate/values"];
    },
    error(): any {
      return this.$store.getters["auth/error"];
    },
    loading(): any {
      return this.$store.getters["assessmentTemplate/loading"];
    },
    formName(): any {
      return this.$store.getters["assessmentTemplate/formName"];
    },
    hasValuesAndTraits(): any {
      return this.$store.getters["assessmentTemplate/hasValuesAndTraits"];
    },
    hasOaicArchetypes(): any {
      return this.$store.getters["assessmentTemplate/hasOaicArchetypes"];
    },
    hasCustomSurvey(): any {
      return this.$store.getters["assessmentTemplate/hasCustomSurvey"];
    },
    hasAcceptableBehaviors(): any {
      return this.$store.getters["assessmentTemplate/hasAcceptableBehaviors"];
    },
    isLightColor(): any {
      const user = this.$store.getters["user/user"] || {};
      const color = user.color || "#000";
      return helpers.isLightColor(color);
    },
    valuesAndTraitsLoading(): boolean {
      return this.$store.getters["aiAssistedSetup/valuesAndTraitsLoading"];
    },
    cultureProfileLoading(): boolean {
      return this.$store.getters["aiAssistedSetup/cultureProfileLoading"];
    },
    aiAssessmentTemplateFinished(): boolean {
      return this.$store.getters["aiAssessmentTemplate/finished"];
    },
    coreValueEnabled(): boolean {
      if (!this.form.coreValue.length) return false;
      if (this.form.coreValueSearched) {
        const currentSearch = this.form.coreValue.join(", ");
        const lastSearch = this.form.lastCoreValue.join(", ");
        if (currentSearch == lastSearch) return false;
      }
      return true;
    },
    isShowedGuidedTourCreate() {
      return this.guidedTourCreateEnabled && !this.guidedTourCreateDone;
    },
    tempFormData() {
      return this.$store.getters["aiAssessmentTemplate/tempFormData"];
    },
    autoSelectSectionsResult() {
      return this.$store.getters[
        "aiAssessmentTemplate/autoSelectSectionsResult"
      ];
    },
  },
  data() {
    return {
      debug: false,
      isNew: false,
      noTeam: true,
      isUserMode: false,
      isStartedTest: false,
      canEdit: true,
      isEditRedirectLink: false,
      redirectUrl: "",
      step: 1, // 1
      publishSubmitStatus: true,
      intendedforOptions: [
        "Hiring_candidates",
        "New_Hires",
        "Current_team_members",
      ],
      assessmentPermissions: ["personal", "shared"],
      LANGUAGES_REQUIRED_ENABLED: true,
      HUMAN_SKILLS_ENABLED: true,
      AI_ASSISTED_SETUP: false,
      BACK_STEP_ENABLED: true,
      RELOAD_TO_EDIT_ENABLED: true,
      stepNames: ["name", "select", "setup", "review"],
      isEnabledInputForAi: false,
      form: {
        name: "",
        intendedfor: "",
        scope: "",
        sharingResults: true,
        openEndedQuestion: true,
        extraTime: "",
        langRequired: [] as any,
        coreValue: [] as any,
        coreValueSearched: false as any,
        lastCoreValue: [] as any,
        redirectUrl: "",
        sections: {
          values_and_traits: false,
          OAIC_archetypes: false,
          acceptable_behaviors: false,
          work_style_identifier: false,
          human_skills: false,
          logic_test: false,
          custom_survey: false,
        },
        setupSections: {
          values_and_traits: false,
          OAIC_archetypes: false,
          acceptable_behaviors: false,
          work_style_identifier: false,
          human_skills: false,
          logic_test: false,
          custom_survey: false,
        },
        version: 0,
        roleSelected: "",
        typeOfOrgSelected: "",
        coreValuesSelected: [] as string[],
      },
      validateForm: {
        name: false,
        intendedfor: false,
        scope: false,
        langRequired: false,
        setupSections: {
          values_and_traits: false,
          OAIC_archetypes: false,
          acceptable_behaviors: false,
          work_style_identifier: false,
          logic_test: false,
          custom_survey: false,
        },
        roleSelected: false,
        typeOfOrgSelected: false,
        coreValuesSelected: false,
      },
      langRequired: [
        {
          value: "English",
          label: "English",
        },
        {
          value: "Thai",
          label: "Thai - ไทย",
        },
      ],
      sectionListStepTwo: [
        // {
        //   title: "Values_and_Traits",
        //   description: [{ class: "", label: "Values_and_traits_influence" }],
        //   description_end: "Determine_values_traits",
        //   timeDefault: 5,
        //   keyValue: "values_and_traits",
        // },
        // {
        //   title: "Culture_Profile",
        //   description: [
        //     { class: "", label: "Organizational_culture_is_driven" },
        //     { class: "fw-700", label: "Clan_Create_Collaborate_and" },
        //     { class: "", label: "across_six_dimensions_of" },
        //   ],
        //   description_end: "Determine_culture_profile",
        //   timeDefault: 5,
        //   keyValue: "OAIC_archetypes",
        // },
        // {
        //   title: "Human_Skills",
        //   description: [{ class: "", label: "Human_skills_soft_skills" }],
        //   description_end: "Evaluate_human_skills",
        //   timeDefault: 10,
        //   keyValue: "human_skills",
        // },
        // {
        //   title: "Acceptable_Behaviors",
        //   description: [{ class: "", label: "Behaviors_are_considered_toxic" }],
        //   description_end: "Determine_expected_behaviors_and",
        //   timeDefault: 5,
        //   keyValue: "acceptable_behaviors",
        // },
        // {
        //   title: "Work_Style_Identifier",
        //   description: [
        //     { class: "", label: "Identify_a_candidate_work_style" },
        //     { class: "fw-700", label: "Product_Process_or_People" },
        //     { class: "", label: "Understanding_the_inclination_for" },
        //   ],
        //   description_end: "",
        //   timeDefault: 5,
        //   keyValue: "work_style_identifier",
        // },
      ] as any,
      coreValueTags: [
        {
          label: "Purpose-driven",
          id: "purpose-driven",
        },
        {
          label: "Human-centric",
          id: "human-centric",
        },
        {
          label: "Disruptive thinking",
          id: "disruptive_thinking",
        },
        {
          label: "Customer obsession",
          id: "customer-obsession",
        },
        {
          label: "Bold experimentation",
          id: "bold-experimentation",
        },
        {
          label: "Continuous learning",
          id: "continuous_learning",
        },
        {
          label: "Radical transparency",
          id: "radical_transparency",
        },
        {
          label: "Growth-mindset",
          id: "growth-mindset",
        },
      ],
      aiLoading: false,
      setupByAILoadingStatus: {
        values_and_traits: false,
        OAIC_archetypes: false,
        acceptable_behaviors: false,
        // work_style_identifier: false,
        // human_skills: false,
      },
      setupByAIStatus: {
        values_and_traits: false,
        OAIC_archetypes: false,
        acceptable_behaviors: false,
        // work_style_identifier: false,
        // human_skills: false,
      },
      setUpValuesAndTraitsModalStatus: false,
      setUpOAIC_ArchetypesModalStatus: false,
      setUpAcceptableBehaviorsModalStatus: false,
      setUpCustomSurveyModalStatus: false,
      selectedExistsTemplateValues: "",
      selectedExistsTemplateOAIC_Archetypes: "",
      selectedExistsTemplateAcceptableBehaviors: "",
      selectedExistsTemplateCustomSurvey: "",

      // detail dialogs
      valuesDetailModalStatus: false,
      cultureProfileDetailModalStatus: false,
      humanSkillsDetailModalStatus: false,
      acceptableBehaviorsDetailModalStatus: false,
      workStyleIdentifierDetailModalStatus: false,
      logicTestDetailModalStatus: false,
      customSurveyDetailModalStatus: false,

      guidedTourCreateEnabled: false,
      guidedTourCreateDone: false,

      // Editable Input
      orgTypeEditable: true,
      coreValuesEditable: true,

      // Represent of created or edit flow
      routeId: "",
    };
  },
  methods: {
    onModalOpened() {
      //
    },
    getPurposeDescription() {
      let text = this.form.intendedfor;
      if (this.isEnabledInputForAi) {
        const variables = {
          RoleFocus: this.form.roleSelected,
          Purpose: this.form.intendedfor,
          OrganizationType: this.form.typeOfOrgSelected,
        };
        const langKey = this.form.roleSelected
          ? "Purpose_with_role_varaiables"
          : "Purpose_with_none_role_varaiables";
        text = (this as any).$t(langKey, variables);
      }
      return text;
    },
    isGeneratedByAi(section: string) {
      return _.get(this.setupByAIStatus, section, false);
    },
    isLoadingByAi(section: string) {
      return _.get(this.setupByAILoadingStatus, section, false);
    },
    setupAllSubformsStatus() {
      const sections = _.get(this.tempFormData, "sections", []);
      _.each(sections, (section) => {
        this.form.sections[section] = true;
      });
    },
    // we know what sections are selected
    // show tabs and loading status if neccessary
    beforeGenerateSubformsByAi() {
      this.aiLoading = true;

      // Values and Traits
      if (_.get(this.autoSelectSectionsResult, "val_a", false)) {
        this.form.sections.values_and_traits = true;
        this.setupByAILoadingStatus.values_and_traits = true;
      }

      // Culture Profile (OAIC Archtypes)
      if (_.get(this.autoSelectSectionsResult, "culture_p", false)) {
        this.form.sections.OAIC_archetypes = true;
        this.setupByAILoadingStatus.OAIC_archetypes = true;
      }

      // Acceptable Behaviors based on role
      if (_.get(this.autoSelectSectionsResult, "accept_be", false)) {
        this.form.sections.acceptable_behaviors = true;
        this.setupByAILoadingStatus.acceptable_behaviors = true;
      }

      // Human Skills
      if (_.get(this.autoSelectSectionsResult, "human_skills", false)) {
        this.form.sections.human_skills = true;
        this.setupByAILoadingStatus.human_skills = true;
      }

      // Logic Test
      if (_.get(this.autoSelectSectionsResult, "logic_t", false)) {
        this.form.sections.logic_test = true;
        this.setupByAILoadingStatus.logic_test = true;
      }

      // Work Style Identifier
      if (_.get(this.autoSelectSectionsResult, "work_style", false)) {
        this.form.sections.work_style_identifier = true;
        this.setupByAILoadingStatus.work_style_identifier = true;
      }
    },
    afterGenerateSubformsByAi() {
      this.setupAllSubformsStatus();
      for (const k in this.setupByAILoadingStatus) {
        this.setupByAILoadingStatus[k] = false;
      }
      this.aiLoading = false;
    },
    makeVariables(formDetailsDto: any) {
      const variables = {
        formId: this.formId,
        purpose: formDetailsDto.objective,
        role: formDetailsDto.role,
        typeOfOrg: formDetailsDto.type_of_org,
        coreValues: formDetailsDto.core_values,
        languagesRequired: formDetailsDto.languagesRequired,
      };
      return variables;
    },
    async autoSelectSections(formDetailsDto: any) {
      const variables = this.makeVariables(formDetailsDto);
      await this.$store.dispatch(
        "aiAssessmentTemplate/autoSelectSections",
        variables,
        {
          root: true,
        }
      );
    },
    async generateSubformsByAi(formDetailsDto: any) {
      await this.autoSelectSections(formDetailsDto);
      this.beforeGenerateSubformsByAi();

      // // @fixed testing
      // setTimeout(() => {
      //   this.afterGenerateSubformsByAi();
      // }, 3000);
      // if (this.$store) return;

      const variables = this.makeVariables(formDetailsDto);
      await this.$store.dispatch(
        "aiAssessmentTemplate/generateSubformsByAi",
        variables,
        {
          root: true,
        }
      );

      // It's stream search, so we don't need to wait for the result
      // Use status from store instead
      // @see afterGenerateSubformsByAi()
    },
    lang(obj: any, attr: string) {
      return helpers.objectLang(obj, attr);
    },
    join(arr: any, separator: string) {
      if (Array.isArray(arr)) {
        return arr.join(separator);
      }
      return "";
    },
    yourSectionsStyles(): string {
      if (this.yourSelectedItemsTotal > 5) {
        return "min-height: 136px";
      } else if (this.yourSelectedItemsTotal == 5) {
        if (this.aiLoading) {
          return "min-height: 136px";
        }
      }
      return "";
    },
    isIntendedforChecked(value: string): boolean {
      // @fixed ugly values

      // Hiring_candidates: "Hiring",
      // New_Hires: "Onboarding",
      // Current_team_members: "Team-building",
      const enOptions = ["Hiring", "Onboarding", "Team-building"];

      // Hiring_candidates: "ผู้สมัครงาน",
      // New_Hires: "พนักงานใหม่",
      // Current_team_members: "สมาชิกทีมปัจจุบัน",
      const thOptions = ["ผู้สมัครงาน", "พนักงานใหม่", "สมาชิกทีมปัจจุบัน"];

      const systemLang = (this as any).$i18n.getLocale();
      const valueLang =
        thOptions.indexOf(this.form.intendedfor) !== -1 ? "th" : "en";

      if (systemLang == valueLang) {
        return (
          this.form.intendedfor == (this as any).$i18n.t("optional." + value)
        );
      } else {
        let index, aliasValue;
        if (systemLang == "th") {
          index = enOptions.indexOf(this.form.intendedfor);
          aliasValue = thOptions[index];
        } else {
          index = thOptions.indexOf(this.form.intendedfor);
          aliasValue = enOptions[index];
        }

        return aliasValue == (this as any).$i18n.t("optional." + value);
      }
    },
    isAssessemntPermissionChecked(value: string): boolean {
      // console.log(`isAssessemntPermissionChecked(${value})`);
      // console.log(this.form.scope, "this.form.scope");
      return this.form.scope == value;
    },
    backToLastPage() {
      // console.log("backToLastPage()");
      this.$router.push({ name: "Assessments" });
    },
    gotoPreviousStep() {
      if (this.isStartedTest) {
        this.step = 1;
      } else {
        if (this.step == 4) {
          this.step = 2;
        } else if (this.step > 1) {
          this.step--;
        }
      }
    },
    async gotoNextStep() {
      if (!this.canNextStep) return;
      switch (this.step) {
        case 1:
          this.oneNextstep();
          break;
        case 2:
          if (this.aiLoading) return;
          await this.twoNextstep();
          this.threeNextstep();
          break;
      }
    },
    noop() {
      //
    },
    activeClass() {
      return "active";
    },
    active() {
      //
    },
    openDetailDialog(section: string) {
      if (section == "values_and_traits") this.valuesDetailModalStatus = true;
      else if (section == "OAIC_archetypes")
        this.cultureProfileDetailModalStatus = true;
      else if (section == "human_skills")
        this.humanSkillsDetailModalStatus = true;
      else if (section == "acceptable_behaviors")
        this.acceptableBehaviorsDetailModalStatus = true;
      else if (section == "work_style_identifier")
        this.workStyleIdentifierDetailModalStatus = true;
      else if (section == "logic_test") this.logicTestDetailModalStatus = true;
      else if (section == "custom_survey")
        this.customSurveyDetailModalStatus = true;
    },
    nl2br(text: string) {
      return helpers.nl2br(text);
    },
    str2list(text: string): string[] {
      const lines = text.split("\n").map((v) => {
        return _.trim(v).replace("- ", "");
      });
      return lines;
    },
    text2ul(text: string) {
      const lines = text.split("\n");
      const bullet = `<svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8 4.5C8 6.70914 6.20914 8.5 4 8.5C1.79086 8.5 0 6.70914 0 4.5C0 2.29086 1.79086 0.5 4 0.5C6.20914 0.5 8 2.29086 8 4.5ZM2.4 4.5C2.4 5.38366 3.11634 6.1 4 6.1C4.88366 6.1 5.6 5.38366 5.6 4.5C5.6 3.61634 4.88366 2.9 4 2.9C3.11634 2.9 2.4 3.61634 2.4 4.5Z" fill="#1B1C1E"/>
</svg>
`;
      let ul = '<ul class="list">';
      for (const i in lines) {
        const line = lines[i];
        let value = _.trim(line).replace("- ", "");
        ul += `<li>${bullet}${value}</li>`;
      }
      ul += "</ul>";

      return ul;
    },
    onIntendForChange(event: any) {
      this.form.intendedfor = event.target.value;
    },
    onAssessmentPermissionChange(event: any) {
      this.form.scope = event.target.value;
    },
    setupByAI() {
      // if (
      //   this.setupByAILoadingStatus.values_and_traits ||
      //   this.setupByAILoadingStatus.OAIC_archetypes
      // ) {
      //   return;
      // }

      // Values and Traits
      if (this.form.sections.values_and_traits) {
        this.setupByAILoadingStatus.values_and_traits = true;
        this.$store.dispatch(
          "aiAssistedSetup/setup",
          { section: "values", values: this.form.coreValue },
          {
            root: true,
          }
        );
        this.$store.dispatch(
          "aiAssistedSetup/setup",
          { section: "traits", values: this.form.coreValue },
          {
            root: true,
          }
        );
      }

      // Culture Profile (OAIC Archtypes)
      if (this.form.sections.OAIC_archetypes) {
        this.setupByAILoadingStatus.OAIC_archetypes = true;
        this.$store.dispatch(
          "aiAssistedSetup/setup",
          { section: "culture_profile", values: this.form.coreValue },
          {
            root: true,
          }
        );
      }

      // Save core values
      this.$store.dispatch(
        "assessmentTemplate/saveCoreValues",
        this.form.coreValue,
        { root: true }
      );

      // Save new core values for use it in the future by latest core values
      this.$store.dispatch(
        "coreValuesAnalyzer/saveValues",
        this.form.coreValue,
        {
          root: true,
        }
      );

      this.form.coreValueSearched = true;
      this.form.lastCoreValue = _.clone(this.form.coreValue);
    },
    proceedWithManualSetup() {
      const top_of_setup_card = (this.$refs as any).top_of_setup_card;
      if (top_of_setup_card) {
        top_of_setup_card.scrollIntoView({ behavior: "smooth" });
      }
    },
    backStep() {
      if (!this.BACK_STEP_ENABLED) {
        this.$router.go(-1);
        return;
      }
      if (this.step > 1) {
        this.step--;
        this.$router.push({
          params: { step: this.stepNames[this.step - 1] },
        });
      } else {
        this.$router.go(-1);
      }
    },
    gotoStep(step: number) {
      if (!this.RELOAD_TO_EDIT_ENABLED) return;
      if (this.step > step) {
        this.step = step;
      }

      // TODO: jump to forward
    },
    btnNextStepCls(step: number) {
      if (!this.RELOAD_TO_EDIT_ENABLED) return;
      let cls = "";
      if (this.step > step) {
        cls += " box-step-clickable";
      }
      return cls;
    },
    tabNextStepCls(step: number) {
      let cls = "";
      // reduce 4 to 3 steps (2 eq 3)
      if (step == 2) {
        cls = this.step == 2 || this.step == 3 ? "active" : "";
      } else if (step == 3) {
        cls = this.step == 4 ? "active" : "";
      } else {
        cls = this.step == step ? "active" : "";
      }

      if (cls != "active") {
        // console.log(step, "step");
        // console.log(this.step, "this.step");
        if (step < this.step) {
          cls = "passed";
        }
      }

      return cls;
    },
    validateStyle(status: boolean) {
      if (status) {
        return "validate-true";
      }
      return "validate-false";
    },
    async oneNextstep() {
      if (this.aiLoading) return;

      this.validateForm.name = false;
      if (!this.form.name) {
        this.validateForm.name = true;
      }
      this.validateForm.intendedfor = false;
      if (!this.form.intendedfor) {
        this.validateForm.intendedfor = true;
      }

      const valid = !this.validateForm.intendedfor && !this.validateForm.name;
      if (valid) {
        // update form name and objective
        const formDetailsDto: any = {
          _id: this.formId,
          form_name: this.form.name,
          objective: this.form.intendedfor,
          scope: this.form.scope,
          languagesRequired: this.form.langRequired,
          callback: () => {
            // console.log("noop...");
          },
        };

        if (this.isEnabledInputForAi) {
          // Set inputs for AI generation
          formDetailsDto.role = _.get(this.form, "roleSelected", "");
          formDetailsDto.type_of_org = _.get(
            this.form,
            "typeOfOrgSelected",
            ""
          );
          formDetailsDto.core_values = _.get(
            this.form,
            "coreValuesSelected",
            []
          );

          // role is not required for AI generation
          let canGenerateSubformsByAi =
            formDetailsDto.type_of_org && formDetailsDto.core_values.length > 1;

          // @todo is changed or not. Depends on requirements
          // @todo if we want to use this feature only once
          // const isGeneratedByAi = _.get(
          //   this.tempFormData,
          //   "generated_by_ai",
          //   false
          // );
          // if (isGeneratedByAi) {
          //   canGenerateSubformsByAi = false;
          // }

          // always generate subforms by AI
          // I beleive change this when edit in the future
          if (canGenerateSubformsByAi) {
            canGenerateSubformsByAi = this.routeId.length == 0;
          }

          if (canGenerateSubformsByAi) {
            this.aiLoading = true;
            await this.generateSubformsByAi(formDetailsDto);
          }
        }

        await this.$store.dispatch(
          "assessmentTemplate/saveFormDetails",
          formDetailsDto,
          { root: true }
        );

        const preventToEdit = false;
        if (preventToEdit && this.isStartedTest) {
          this.step = 4;
          this.$router.push({
            params: { step: "review" },
          });
        } else {
          this.step = 2;
          this.$router.push({
            params: { step: "select" },
          });
        }
      }
    },
    handleCommand(command: string, value: any) {
      if (command == "setExtraTime") {
        this.form.extraTime = value;
      } else if (command == "setSharingResults") {
        this.form.sharingResults = value;
      } else if (command == "setOpenEndedQuestion") {
        this.form.openEndedQuestion = value;
      }
    },
    saveRedirectUrl() {
      if (!this.redirectUrl) return false;
      const cls = this.checkingItIsURL(this.redirectUrl);
      if (cls == "validate-true") return false;
      this.form.redirectUrl = this.redirectUrl;
      this.isEditRedirectLink = false;
    },
    async oneNextstepStartedTest() {
      await this.oneNextstep();
      this.step = 1;
      this.$router.push({
        params: { step: "name" },
      });
      await this.publishSubmit();
    },
    async twoNextstep() {
      if (!this.btnNexstepTwo) return;
      const value =
        this.form.sections.values_and_traits ||
        this.form.sections.OAIC_archetypes ||
        this.form.sections.human_skills ||
        this.form.sections.acceptable_behaviors ||
        this.form.sections.work_style_identifier ||
        this.form.sections.logic_test ||
        this.form.sections.custom_survey;

      if (value) {
        if (!this.form.coreValue.length) {
          if (this.AI_ASSISTED_SETUP) {
            await this.$store.dispatch(
              "aiAssistedSetup/initDefaultCoreValues",
              null,
              {
                root: true,
              }
            );
            const defaultCoreValues =
              this.$store.getters["aiAssistedSetup/defaultCoreValues"] || [];
            for (const i in defaultCoreValues) {
              this.form.coreValue.push(defaultCoreValues[i]);
            }
          }
        }

        this.step = 3;
        this.$router.push({
          params: { step: "setup" },
        });
      } else {
        ElMessage({
          message: (this as any).$t("popup.message.message_14w"),
          type: "warning",
        });
      }
    },
    async useSelectedExistsTemplate(
      formId: string,
      section: string,
      _id: string
    ) {
      if (_id && _id != "-1" && _id != "0") {
        const selectedExistsTemplate = _.find(this.existsTemplates, {
          _id: _id,
        });
        if (selectedExistsTemplate) {
          await this.$store.dispatch(
            "assessmentTemplate/loadFromExistsTemplate",
            {
              formId: formId,
              selectedExistsTemplate: selectedExistsTemplate,
              section: section,
            },
            {
              root: true,
            }
          );
        }
      } else if (_id == "0") {
        // Proceed with a new setup
        await this.$store.dispatch(
          "assessmentTemplate/proceedWithNewSetup",
          {
            formId: formId,
            section: section,
          },
          {
            root: true,
          }
        );
      } else {
        if (section == "values_and_traits") {
          const valuesAlignmentVersionId = _.get(
            this.values,
            "values_and_traits_version_id",
            ""
          );
          this.$store.dispatch(
            "assessmentSection/setValuesAndTraitsVersionId",
            valuesAlignmentVersionId,
            { root: true }
          );
        }
      }
    },
    setUpValuesAndTraits(formId: string) {
      this.setUpValuesAndTraitsModal(formId);
    },
    async setUpValuesAndTraitsModal(formId: string) {
      // console.log(`setUpValuesAndTraitsModal(${formId})`);
      if (this.setUpValuesAndTraitsModalStatus) return;
      this.setUpValuesAndTraitsModalStatus = true;
      await this.$store.dispatch(
        "assessmentTemplate/load",
        { formId: formId, section: "values_and_traits", doNotMergeTemp: true },
        {
          root: true,
        }
      );
    },
    async setUpValuesAndTraitsNewTab(formId: string) {
      let routeData = this.$router.resolve({
        name: "AssessmentsCreateSetUpTaluesAndTraits",
        query: { id: formId },
      });

      await this.useSelectedExistsTemplate(
        formId,
        "values_and_traits",
        this.selectedExistsTemplateValues
      );

      this.setUpValuesAndTraitsModalStatus = false;
      window.open(routeData.href, "_blank");

      this.form.setupSections.values_and_traits = true;
    },
    setUpOAIC_Archetypes(formId: string) {
      this.setUpOAIC_ArchetypesModal(formId);
    },
    async setUpOAIC_ArchetypesModal(formId: string) {
      if (this.setUpOAIC_ArchetypesModalStatus) return;
      this.setUpOAIC_ArchetypesModalStatus = true;
      await this.$store.dispatch(
        "assessmentTemplate/load",
        { formId: formId, section: "OAIC_archetypes", doNotMergeTemp: true },
        {
          root: true,
        }
      );
    },
    async setUpOAIC_ArchetypesNewTab(formId: string) {
      let routeData = this.$router.resolve({
        name: "SetupOAICArchetypes",
        query: { id: formId },
      });

      await this.useSelectedExistsTemplate(
        formId,
        "OAIC_archetypes",
        this.selectedExistsTemplateOAIC_Archetypes
      );

      this.setUpOAIC_ArchetypesModalStatus = false;
      window.open(routeData.href, "_blank");

      this.form.setupSections.OAIC_archetypes = true;
    },
    setUpAcceptableBehaviors(formId: string) {
      this.setUpAcceptableBehaviorsModal(formId);
    },
    async setUpAcceptableBehaviorsModal(formId: string) {
      if (this.setUpAcceptableBehaviorsModalStatus) return;
      this.setUpAcceptableBehaviorsModalStatus = true;
      await this.$store.dispatch(
        "assessmentTemplate/load",
        {
          formId: formId,
          section: "acceptable_behaviors",
          doNotMergeTemp: true,
        },
        {
          root: true,
        }
      );
    },
    async setUpAcceptableBehaviorsNewTab(formId: string) {
      let routeData = this.$router.resolve({
        name: "SetupAcceptableBehaviors",
        query: { id: formId },
      });

      await this.useSelectedExistsTemplate(
        formId,
        "acceptable_behaviors",
        this.selectedExistsTemplateAcceptableBehaviors
      );

      this.setUpAcceptableBehaviorsModalStatus = false;
      window.open(routeData.href, "_blank");

      this.form.setupSections.acceptable_behaviors = true;
    },
    setUpCustomSurvey(formId: string) {
      this.setUpCustomSurveyModal(formId);
    },
    async setUpCustomSurveyModal(formId: string) {
      if (this.setUpCustomSurveyModalStatus) return;
      this.setUpCustomSurveyModalStatus = true;
      await this.$store.dispatch(
        "assessmentTemplate/load",
        { formId: formId, section: "custom_survey", doNotMergeTemp: true },
        {
          root: true,
        }
      );
    },
    async setUpCustomSurveyNewTab(formId: string) {
      let routeData = this.$router.resolve({
        name: "SetUpCustomSurvey",
        query: { id: formId },
      });

      await this.useSelectedExistsTemplate(
        formId,
        "custom_survey",
        this.selectedExistsTemplateCustomSurvey
      );

      this.setUpCustomSurveyModalStatus = false;
      window.open(routeData.href, "_blank");

      this.form.setupSections.custom_survey = true;
    },
    threeNextstep() {
      if (this.btnNexstepThree) {
        this.step = 4;
        this.$router.push({
          params: { step: "review" },
        });
      } else {
        ElMessage({
          message: (this as any).$t("popup.message.message_15w"),
          type: "warning",
        });
      }
    },
    submitEventPublish() {
      this.publishSubmitStatus = false;
    },
    async publishSubmit() {
      // console.log("publishSubmit()");

      // console.log(
      //   this.isShowedGuidedTourCreate,
      //   "this.isShowedGuidedTourCreate"
      // );

      this.submitEventPublish();
      const isNew = this.$store.getters["assessmentTemplate/isNew"];
      const method = isNew
        ? "createAssessmentTemplateFromTempForm"
        : "updateAssessmentTemplateFromTempForm";
      const callback = () => {
        this.publishSubmitStatus = true;
        this.$router.replace("/assessments");
      };
      const params = this.$router.currentRoute.value.params;
      const routeId = params.routeId || "";

      const formData: any = {
        formId: this.formId,
        routeId: routeId,
        callback: callback,
        extraTime: this.form.extraTime,
        autoSendResult: this.form.sharingResults,
        openEndedQuestion: this.form.openEndedQuestion,
        redirectUrl: this.form.redirectUrl,
      };

      if (isNew) {
        formData.isCreatedByGuidedTour = this.isShowedGuidedTourCreate;
      }

      // console.log(formData, "formData");
      // if (this.$store) return;

      await this.$store.dispatch("assessmentTemplate/" + method, formData, {
        root: true,
      });
    },
    onChangeFormName(event: any) {
      const value = event.target.value;
      this.$store.commit("assessmentTemplate/formName", value, { root: true });
    },
    checkingItIsURL(url: string) {
      if (url === "") return "";
      var expression =
        /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
      const regex = new RegExp(expression);
      if (url.match(regex)) {
        return "validate-false";
      }
      return "validate-true";
    },
    onChangeObjective(event: any) {
      const value = event.target.value;
      this.$store.commit("assessmentTemplate/objective", value, { root: true });
    },
    _onClickSection(name: string) {
      // Can't dynamic assign property value
      switch (name) {
        case "values_and_traits":
          this.form.sections.values_and_traits =
            !this.form.sections.values_and_traits;
          break;
        case "OAIC_archetypes":
          this.form.sections.OAIC_archetypes =
            !this.form.sections.OAIC_archetypes;
          break;
        case "human_skills":
          this.form.sections.human_skills = !this.form.sections.human_skills;
          break;
        case "acceptable_behaviors":
          this.form.sections.acceptable_behaviors =
            !this.form.sections.acceptable_behaviors;
          break;
        case "work_style_identifier":
          this.form.sections.work_style_identifier =
            !this.form.sections.work_style_identifier;
          break;
        case "logic_test":
          this.form.sections.logic_test = !this.form.sections.logic_test;
          break;
        case "custom_survey":
          this.form.sections.custom_survey = !this.form.sections.custom_survey;
          break;
      }
    },
    onClickSection(name: string) {
      this._onClickSection(name);
      this.$store.dispatch("assessmentTemplate/toggleSection", name);
    },
  },
});
