import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle } from "vue"
import _imports_0 from '@/assets/icons/search.svg'
import _imports_1 from '@/assets/icons/more.svg'
import _imports_2 from '@/assets/icons/menu_edit.svg'
import _imports_3 from '@/assets/icons/menu_copy.svg'
import _imports_4 from '@/assets/icons/menu_delete.svg'


const _hoisted_1 = {
  class: "box-card",
  style: {"background-color":"#fff"}
}
const _hoisted_2 = { class: "" }
const _hoisted_3 = {
  key: 0,
  class: "flex-mid fs-18 fw-500",
  style: {"height":"50rem"}
}
const _hoisted_4 = { class: "center" }
const _hoisted_5 = { class: "fs-24 fw-700 fc-primary" }
const _hoisted_6 = {
  class: "fs-14 fw-400 mt-24",
  style: {"color":"#696c80"}
}
const _hoisted_7 = {
  key: 1,
  class: "flex-center p-20"
}
const _hoisted_8 = {
  class: "input-container-02 mr-8",
  style: {"width":"31.2rem"}
}
const _hoisted_9 = ["placeholder"]
const _hoisted_10 = {
  key: 0,
  class: "box-card mt-12",
  style: {"background-color":"#fff","overflow":"hidden"}
}
const _hoisted_11 = { class: "table02" }
const _hoisted_12 = {
  class: "flex-center",
  style: {"width":"30rem"}
}
const _hoisted_13 = { class: "arrow-sorting" }
const _hoisted_14 = { class: "flex-center" }
const _hoisted_15 = { class: "arrow-sorting pr-12" }
const _hoisted_16 = { class: "progress-width" }
const _hoisted_17 = { class: "flex-center" }
const _hoisted_18 = { class: "arrow-sorting" }
const _hoisted_19 = { class: "flex-center" }
const _hoisted_20 = { class: "arrow-sorting" }
const _hoisted_21 = { class: "flex-center" }
const _hoisted_22 = { class: "arrow-sorting" }
const _hoisted_23 = ["onClick"]
const _hoisted_24 = {
  class: "fc-black-font",
  style: {"display":"flex","align-items":"center","gap":"12px"}
}
const _hoisted_25 = {
  key: 0,
  xmlns: "http://www.w3.org/2000/svg",
  width: "14",
  height: "16",
  viewBox: "0 0 14 16",
  fill: "none"
}
const _hoisted_26 = ["innerHTML"]
const _hoisted_27 = { class: "fc-gray-font-2" }
const _hoisted_28 = { class: "progress-width" }
const _hoisted_29 = { class: "table-data progress pr-12" }
const _hoisted_30 = { class: "relative" }
const _hoisted_31 = { class: "pr-32 progress-label fc-gray-font-2" }
const _hoisted_32 = { class: "fc-gray-font-2" }
const _hoisted_33 = { class: "pr-12" }
const _hoisted_34 = { class: "fc-gray-font-2" }
const _hoisted_35 = { class: "pr-12" }
const _hoisted_36 = { align: "right" }
const _hoisted_37 = { class: "menu-dropdown flex-center" }
const _hoisted_38 = {
  key: 0,
  class: "fs-14 flex-mid opa-07",
  style: {"height":"12rem"}
}
const _hoisted_39 = { style: {"padding-top":"120px","padding-bottom":"40px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_popover = _resolveComponent("el-popover")!
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!
  const _component_pagination = _resolveComponent("pagination")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (!_ctx.tableData.length && !_ctx.loading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t("setup.Get_Started_by_Creating_a_New_Assessment")), 1),
                _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t(
                "setup.Youre_just_10_minutes_away_from_evaluating_cultural_fit_for_your_next_hire_new_team_member_or_team_alignment"
              )), 1)
              ])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _withDirectives(_createElementVNode("input", {
                  class: "input input-icon",
                  type: "email",
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchInput) = $event)),
                  placeholder: _ctx.$t('placeholder.Search_by_name')
                }, null, 8, _hoisted_9), [
                  [_vModelText, _ctx.searchInput]
                ]),
                _cache[12] || (_cache[12] = _createElementVNode("img", {
                  src: _imports_0,
                  alt: "search icon",
                  class: "image-icon"
                }, null, -1))
              ])
            ]))
      ])
    ]),
    (!(!_ctx.tableData.length || _ctx.loading))
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createElementVNode("table", _hoisted_11, [
            _createElementVNode("thead", null, [
              _createElementVNode("tr", null, [
                _cache[13] || (_cache[13] = _createElementVNode("th", null, [
                  _createElementVNode("div", { style: {"width":"3.2rem"} })
                ], -1)),
                _createElementVNode("th", null, [
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t("headerTable.NAME")), 1),
                    _createElementVNode("div", _hoisted_13, [
                      _createElementVNode("div", {
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.fillterValue = 'name_ascending')),
                        class: _normalizeClass([`${_ctx.filterActiveClass('name_ascending')}`, "arrow arrow-top"])
                      }, " ▲ ", 2),
                      _createElementVNode("div", {
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.fillterValue = 'name_descending')),
                        class: _normalizeClass([`${_ctx.filterActiveClass('name_descending')}`, "arrow arrow-down"])
                      }, " ▲ ", 2)
                    ])
                  ])
                ]),
                _createElementVNode("th", null, [
                  _createElementVNode("div", _hoisted_14, [
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t("headerTable.CANDIDATES")), 1),
                    _createElementVNode("div", _hoisted_15, [
                      _createElementVNode("div", {
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.fillterValue = 'candidate_ascending')),
                        class: _normalizeClass([`${_ctx.filterActiveClass('candidate_ascending')}`, "arrow arrow-top"])
                      }, " ▲ ", 2),
                      _createElementVNode("div", {
                        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.fillterValue = 'candidate_descending')),
                        class: _normalizeClass([`${_ctx.filterActiveClass('candidate_descending')}`, "arrow arrow-down"])
                      }, " ▲ ", 2)
                    ])
                  ])
                ]),
                _createElementVNode("th", _hoisted_16, [
                  _createElementVNode("div", _hoisted_17, [
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t("headerTable.PROGRESS")), 1),
                    _createElementVNode("div", _hoisted_18, [
                      _createElementVNode("div", {
                        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.fillterValue = 'progress_ascending')),
                        class: _normalizeClass([`${_ctx.filterActiveClass('progress_ascending')}`, "arrow arrow-top"])
                      }, " ▲ ", 2),
                      _createElementVNode("div", {
                        onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.fillterValue = 'progress_descending')),
                        class: _normalizeClass([`${_ctx.filterActiveClass('progress_descending')}`, "arrow arrow-down"])
                      }, " ▲ ", 2)
                    ])
                  ])
                ]),
                _cache[14] || (_cache[14] = _createElementVNode("th", null, " ", -1)),
                _createElementVNode("th", null, [
                  _createElementVNode("div", _hoisted_19, [
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t("headerTable.LAST_ACTIVITY")), 1),
                    _createElementVNode("div", _hoisted_20, [
                      _createElementVNode("div", {
                        onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.fillterValue = 'last_activity_ascending')),
                        class: _normalizeClass([`${_ctx.filterActiveClass('last_activity_ascending')}`, "arrow arrow-top"])
                      }, " ▲ ", 2),
                      _createElementVNode("div", {
                        onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.fillterValue = 'last_activity_descending')),
                        class: _normalizeClass([`${_ctx.filterActiveClass('last_activity_descending')}`, "arrow arrow-down"])
                      }, " ▲ ", 2)
                    ])
                  ])
                ]),
                _createElementVNode("th", null, [
                  _createElementVNode("div", _hoisted_21, [
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t("headerTable.DATE_CREATED")), 1),
                    _createElementVNode("div", _hoisted_22, [
                      _createElementVNode("div", {
                        onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.fillterValue = 'date_created_ascending')),
                        class: _normalizeClass([`${_ctx.filterActiveClass('date_created_ascending')}`, "arrow arrow-top"])
                      }, " ▲ ", 2),
                      _createElementVNode("div", {
                        onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.fillterValue = 'date_created_descending')),
                        class: _normalizeClass([`${_ctx.filterActiveClass('date_created_descending')}`, "arrow arrow-down"])
                      }, " ▲ ", 2)
                    ])
                  ])
                ]),
                _cache[15] || (_cache[15] = _createElementVNode("th", null, [
                  _createElementVNode("div", { style: {"width":"4rem"} })
                ], -1)),
                _cache[16] || (_cache[16] = _createElementVNode("th", null, [
                  _createElementVNode("div", { style: {"width":"3.2rem"} })
                ], -1))
              ])
            ]),
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableSliceIndex, (data, index) => {
                return (_openBlock(), _createElementBlock("tr", {
                  key: index + '_table-my-assessments',
                  class: "cursor-pointer",
                  onClick: ($event: any) => (
            _ctx.$router.push({
              name: 'AssessmentsAssessmentsDetails',
              query: { id: data._id },
            })
          )
                }, [
                  _cache[23] || (_cache[23] = _createElementVNode("td", null, [
                    _createElementVNode("div", { style: {"width":"3.2rem"} })
                  ], -1)),
                  _createElementVNode("td", null, [
                    _createVNode(_component_el_popover, {
                      visible: _ctx.isAllowedToShowInvitePopover(data),
                      placement: "bottom",
                      width: 220,
                      "popper-class": "mc-popover"
                    }, {
                      reference: _withCtx(() => [
                        _createElementVNode("div", _hoisted_24, [
                          _createTextVNode(_toDisplayString(data.form_name) + " ", 1),
                          (_ctx.isPrivate(data))
                            ? (_openBlock(), _createElementBlock("svg", _hoisted_25, _cache[17] || (_cache[17] = [
                                _createElementVNode("path", {
                                  d: "M1 9.35C1 7.85011 1 7.10016 1.38197 6.57443C1.50533 6.40464 1.65464 6.25533 1.82443 6.13197C2.35016 5.75 3.10011 5.75 4.6 5.75H9.4C10.8999 5.75 11.6498 5.75 12.1756 6.13197C12.3454 6.25533 12.4947 6.40464 12.618 6.57443C13 7.10016 13 7.85011 13 9.35V9.35C13 11.5998 13 12.7248 12.4271 13.5134C12.242 13.768 12.018 13.992 11.7634 14.1771C10.9748 14.75 9.84984 14.75 7.6 14.75H6.4C4.15016 14.75 3.02524 14.75 2.23664 14.1771C1.98196 13.992 1.75799 13.768 1.57295 13.5134C1 12.7248 1 11.5998 1 9.35V9.35Z",
                                  stroke: "#222222"
                                }, null, -1),
                                _createElementVNode("path", {
                                  d: "M10 5V4.25C10 2.59315 8.65685 1.25 7 1.25V1.25C5.34315 1.25 4 2.59315 4 4.25V5",
                                  stroke: "#222222",
                                  "stroke-linecap": "round"
                                }, null, -1),
                                _createElementVNode("circle", {
                                  cx: "7",
                                  cy: "10.25",
                                  r: "1.5",
                                  fill: "#222222"
                                }, null, -1)
                              ])))
                            : _createCommentVNode("", true)
                        ])
                      ]),
                      default: _withCtx(() => [
                        _createElementVNode("div", {
                          class: "popover-content",
                          innerHTML: _ctx.$t('guided_tour.invite.step_0')
                        }, null, 8, _hoisted_26)
                      ]),
                      _: 2
                    }, 1032, ["visible"])
                  ]),
                  _createElementVNode("td", _hoisted_27, _toDisplayString(data.candidate_total), 1),
                  _createElementVNode("td", _hoisted_28, [
                    _createElementVNode("div", _hoisted_29, [
                      _createElementVNode("div", _hoisted_30, [
                        _cache[18] || (_cache[18] = _createElementVNode("div", { class: "bg-bar" }, null, -1)),
                        _createElementVNode("div", {
                          class: "color-bar bg-primary",
                          style: _normalizeStyle(_ctx.barWidht(data.progress, 100))
                        }, null, 4)
                      ])
                    ])
                  ]),
                  _createElementVNode("td", null, [
                    _createElementVNode("div", _hoisted_31, _toDisplayString(data.progress) + "% ", 1)
                  ]),
                  _createElementVNode("td", _hoisted_32, [
                    _createElementVNode("div", _hoisted_33, _toDisplayString(_ctx.timeAgo(data.last_activity_at)), 1)
                  ]),
                  _createElementVNode("td", _hoisted_34, [
                    _createElementVNode("div", _hoisted_35, _toDisplayString(_ctx.timeAgo(data.published_at)), 1)
                  ]),
                  _createElementVNode("td", _hoisted_36, [
                    _createElementVNode("div", {
                      class: "flex-center ml-auto",
                      style: {"justify-content":"flex-end"},
                      onClick: _cache[11] || (_cache[11] = 
                (event) => {
                  event.stopPropagation();
                }
              )
                    }, [
                      _createVNode(_component_el_dropdown, null, {
                        dropdown: _withCtx(() => [
                          _createVNode(_component_el_dropdown_menu, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_dropdown_item, {
                                onClick: 
                        (event) => {
                          _ctx.handleCommand('edit', data, event);
                        }
                      
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", {
                                    class: _normalizeClass(["menu-dropdown flex-center", { disabled: !_ctx.canEdit(data) }])
                                  }, [
                                    _cache[19] || (_cache[19] = _createElementVNode("div", { class: "mr-12 flex-center" }, [
                                      _createElementVNode("img", {
                                        src: _imports_2,
                                        class: "icon-16",
                                        alt: "icons_edit_20px"
                                      })
                                    ], -1)),
                                    _createElementVNode("div", null, _toDisplayString(_ctx.$t("btn.Edit")), 1)
                                  ], 2)
                                ]),
                                _: 2
                              }, 1032, ["onClick"]),
                              _createVNode(_component_el_dropdown_item, {
                                onClick: ($event: any) => (_ctx.handleCommand('clone', data))
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_37, [
                                    _cache[20] || (_cache[20] = _createElementVNode("div", { class: "mr-12 flex-center" }, [
                                      _createElementVNode("img", {
                                        src: _imports_3,
                                        class: "icon-16",
                                        alt: "clone_20px"
                                      })
                                    ], -1)),
                                    _createElementVNode("div", null, _toDisplayString(_ctx.$t("btn.Duplicate")), 1)
                                  ])
                                ]),
                                _: 2
                              }, 1032, ["onClick"]),
                              _createVNode(_component_el_dropdown_item, {
                                onClick: (event) => { _ctx.handleCommand('delete', data, event); }
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", {
                                    class: _normalizeClass(["menu-dropdown flex-center", { disabled: !_ctx.canEdit(data) }])
                                  }, [
                                    _cache[21] || (_cache[21] = _createElementVNode("div", { class: "mr-12 flex-center" }, [
                                      _createElementVNode("img", {
                                        src: _imports_4,
                                        class: "icon-16",
                                        alt: "delete_20px"
                                      })
                                    ], -1)),
                                    _createElementVNode("div", null, _toDisplayString(_ctx.$t("btn.Delete")), 1)
                                  ], 2)
                                ]),
                                _: 2
                              }, 1032, ["onClick"])
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        default: _withCtx(() => [
                          _cache[22] || (_cache[22] = _createElementVNode("span", { class: "el-dropdown-link" }, [
                            _createElementVNode("div", { class: "image-more-menu flex-min" }, [
                              _createElementVNode("img", {
                                src: _imports_1,
                                alt: "icons_more_vert_black_24dp",
                                class: "icon-16"
                              })
                            ])
                          ], -1))
                        ]),
                        _: 2
                      }, 1024)
                    ])
                  ]),
                  _cache[24] || (_cache[24] = _createElementVNode("td", null, [
                    _createElementVNode("div", { style: {"width":"3.2rem"} })
                  ], -1))
                ], 8, _hoisted_23))
              }), 128))
            ])
          ]),
          (!_ctx.dataFiltered.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_38, " No results found for your search. Sorry! "))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_39, [
      _createVNode(_component_pagination, {
        totalData: _ctx.dataFiltered.length,
        currentPage: _ctx.page,
        onPageChange: _ctx.handlePageChange
      }, null, 8, ["totalData", "currentPage", "onPageChange"])
    ])
  ], 64))
}