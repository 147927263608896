import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx } from "vue"
import _imports_0 from '@/assets/icons/more.svg'
import _imports_1 from '@/assets/icons/menu_message.svg'
import _imports_2 from '@/assets/icons/menu_remove.svg'
import _imports_3 from '@/assets/icons/menu_delete.svg'


const _hoisted_1 = {
  class: "box-card",
  style: {"background-color":"#fff"}
}
const _hoisted_2 = { class: "" }
const _hoisted_3 = {
  key: 0,
  class: "flex-mid fs-18 fw-500",
  style: {"height":"50rem"}
}
const _hoisted_4 = { class: "center" }
const _hoisted_5 = {
  key: 0,
  class: "flex-mid fs-24 fw-700 fc-primary"
}
const _hoisted_6 = {
  key: 1,
  class: "fs-24 fw-700 fc-primary"
}
const _hoisted_7 = {
  class: "fs-14 mt-16",
  style: {"color":"#696c80"}
}
const _hoisted_8 = {
  key: 0,
  class: "box-card mt-12",
  style: {"background-color":"#fff","overflow":"hidden"}
}
const _hoisted_9 = { class: "table02" }
const _hoisted_10 = { class: "flex-center name-cell-width" }
const _hoisted_11 = { class: "arrow-sorting" }
const _hoisted_12 = { class: "flex-center" }
const _hoisted_13 = { class: "arrow-sorting pr-12" }
const _hoisted_14 = { class: "flex-center pr-12" }
const _hoisted_15 = { class: "arrow-sorting" }
const _hoisted_16 = { class: "flex-center" }
const _hoisted_17 = { class: "arrow-sorting" }
const _hoisted_18 = { class: "your-rate-width" }
const _hoisted_19 = { class: "flex-center" }
const _hoisted_20 = { class: "arrow-sorting" }
const _hoisted_21 = ["onClick"]
const _hoisted_22 = { class: "pr-24 name-cell-width" }
const _hoisted_23 = { class: "fs-14 lh-26 ellipsis fc-black-font" }
const _hoisted_24 = {
  class: "fs-12 lh-20 ellipsis",
  style: {"color":"#696c80"}
}
const _hoisted_25 = { class: "fc-gray-font-2" }
const _hoisted_26 = { class: "fc-gray-font-2" }
const _hoisted_27 = { class: "flex-center pr-12 your-rate-width" }
const _hoisted_28 = { align: "right" }
const _hoisted_29 = { class: "menu-dropdown flex-center" }
const _hoisted_30 = {
  key: 0,
  class: "fs-14 flex-mid opa-07",
  style: {"height":"12rem"}
}
const _hoisted_31 = { style: {"padding-top":"120px","padding-bottom":"40px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading = _resolveComponent("loading")!
  const _component_el_rate = _resolveComponent("el-rate")!
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!
  const _component_pagination = _resolveComponent("pagination")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (!_ctx.tableData.length || _ctx.loading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                (_ctx.loading)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _cache[11] || (_cache[11] = _createTextVNode(" Loading ")),
                      _createVNode(_component_loading)
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.$t("dataTable.Invite_your_first")), 1)),
                _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t("dataTable.Send_invitations")), 1)
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    (!(!_ctx.tableData.length || _ctx.loading))
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createElementVNode("table", _hoisted_9, [
            _createElementVNode("thead", null, [
              _createElementVNode("tr", null, [
                _cache[12] || (_cache[12] = _createElementVNode("th", null, [
                  _createElementVNode("div", { style: {"width":"3.2rem"} })
                ], -1)),
                _createElementVNode("th", null, [
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t("headerTable.CANDIDATES")), 1),
                    _createElementVNode("div", _hoisted_11, [
                      _createElementVNode("div", {
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.fillterValue = 'candidates_ascending')),
                        class: _normalizeClass([`${_ctx.filterActiveClass('candidates_ascending')}`, "arrow arrow-top"])
                      }, " ▲ ", 2),
                      _createElementVNode("div", {
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.fillterValue = 'candidates_descending')),
                        class: _normalizeClass([`${_ctx.filterActiveClass('candidates_descending')}`, "arrow arrow-down"])
                      }, " ▲ ", 2)
                    ])
                  ])
                ]),
                _createElementVNode("th", null, [
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t("headerTable.JOINED")), 1),
                    _createElementVNode("div", _hoisted_13, [
                      _createElementVNode("div", {
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.fillterValue = 'joined_ascending')),
                        class: _normalizeClass([`${_ctx.filterActiveClass('joined_ascending')}`, "arrow arrow-top"])
                      }, " ▲ ", 2),
                      _createElementVNode("div", {
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.fillterValue = 'joined_descending')),
                        class: _normalizeClass([`${_ctx.filterActiveClass('joined_descending')}`, "arrow arrow-down"])
                      }, " ▲ ", 2)
                    ])
                  ])
                ]),
                _createElementVNode("th", null, [
                  _createElementVNode("div", _hoisted_14, [
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t("headerTable.SCORE")), 1),
                    _createElementVNode("div", _hoisted_15, [
                      _createElementVNode("div", {
                        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.fillterValue = 'score_ascending')),
                        class: _normalizeClass([`${_ctx.filterActiveClass('score_ascending')}`, "arrow arrow-top"])
                      }, " ▲ ", 2),
                      _createElementVNode("div", {
                        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.fillterValue = 'score_descending')),
                        class: _normalizeClass([`${_ctx.filterActiveClass('score_descending')}`, "arrow arrow-down"])
                      }, " ▲ ", 2)
                    ])
                  ])
                ]),
                _createElementVNode("th", null, [
                  _createElementVNode("div", _hoisted_16, [
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t("headerTable.STATUS")), 1),
                    _createElementVNode("div", _hoisted_17, [
                      _createElementVNode("div", {
                        onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.fillterValue = 'status_ascending')),
                        class: _normalizeClass([`${_ctx.filterActiveClass('status_ascending')}`, "arrow arrow-top"])
                      }, " ▲ ", 2),
                      _createElementVNode("div", {
                        onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.fillterValue = 'status_descending')),
                        class: _normalizeClass([`${_ctx.filterActiveClass('status_descending')}`, "arrow arrow-down"])
                      }, " ▲ ", 2)
                    ])
                  ])
                ]),
                _createElementVNode("th", _hoisted_18, [
                  _createElementVNode("div", _hoisted_19, [
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t("headerTable.YOUR_RATING")), 1),
                    _createElementVNode("div", _hoisted_20, [
                      _createElementVNode("div", {
                        onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.fillterValue = 'your_rate_ascending')),
                        class: _normalizeClass([`${_ctx.filterActiveClass('your_rate_ascending')}`, "arrow arrow-top"])
                      }, " ▲ ", 2),
                      _createElementVNode("div", {
                        onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.fillterValue = 'your_rate_descending')),
                        class: _normalizeClass([`${_ctx.filterActiveClass('your_rate_descending')}`, "arrow arrow-down"])
                      }, " ▲ ", 2)
                    ])
                  ])
                ]),
                _cache[13] || (_cache[13] = _createElementVNode("th", null, [
                  _createElementVNode("div", { style: {"width":"4rem"} })
                ], -1)),
                _cache[14] || (_cache[14] = _createElementVNode("th", null, [
                  _createElementVNode("div", { style: {"width":"3.2rem"} })
                ], -1))
              ])
            ]),
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableSliceIndex, (data, index) => {
                return (_openBlock(), _createElementBlock("tr", {
                  key: index + '_table-my-assessments',
                  class: "cursor-pointer",
                  onClick: ($event: any) => (_ctx.assessmentsCandidate(data))
                }, [
                  _cache[19] || (_cache[19] = _createElementVNode("td", null, [
                    _createElementVNode("div", { style: {"width":"3.2rem"} })
                  ], -1)),
                  _createElementVNode("td", null, [
                    _createElementVNode("div", _hoisted_22, [
                      _createElementVNode("div", _hoisted_23, _toDisplayString(data.display_name), 1),
                      _createElementVNode("div", _hoisted_24, _toDisplayString(data.email), 1)
                    ])
                  ]),
                  _createElementVNode("td", _hoisted_25, _toDisplayString(_ctx.getFormattedDate(data.joined_at)), 1),
                  _createElementVNode("td", _hoisted_26, _toDisplayString(data.score ? Math.round(data.score) + "%" : "-"), 1),
                  _createElementVNode("td", null, [
                    _createElementVNode("div", {
                      class: _normalizeClass(`status-style ${_ctx.statusColor(data.status)}`)
                    }, _toDisplayString((data.score !== null && Math.round(data.score) === 0 && data.status === 'COMPLETED') ? 'PROCESSING RESULTS' : _ctx.workflowStatus(data.status)), 3)
                  ]),
                  _createElementVNode("td", null, [
                    _createElementVNode("div", _hoisted_27, [
                      _createVNode(_component_el_rate, {
                        modelValue: data.rating,
                        "onUpdate:modelValue": ($event: any) => ((data.rating) = $event),
                        onClick: ($event: any) => (_ctx.onClickRating(data, $event)),
                        colors: [_ctx.companyColor, _ctx.companyColor, _ctx.companyColor],
                        "disabled-void-color": "#ddd"
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "onClick", "colors"])
                    ])
                  ]),
                  _createElementVNode("td", _hoisted_28, [
                    _createElementVNode("div", {
                      class: "flex-center ml-auto",
                      style: {"justify-content":"flex-end"},
                      onClick: _cache[10] || (_cache[10] = 
                (event) => {
                  event.stopPropagation();
                }
              )
                    }, [
                      _createVNode(_component_el_dropdown, null, {
                        dropdown: _withCtx(() => [
                          _createVNode(_component_el_dropdown_menu, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_dropdown_item, {
                                onClick: 
                          (event) => {
                            _ctx.handleCommand('resend', data, event);
                          }
                        ,
                                disabled: _ctx.reSendInviteCls(data)
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", {
                                    class: _normalizeClass(["menu-dropdown flex-center", `${_ctx.reSendInviteCls(data) ? 'opa-05' : ''}`])
                                  }, [
                                    _cache[15] || (_cache[15] = _createElementVNode("div", { class: "mr-12 flex-center" }, [
                                      _createElementVNode("img", {
                                        src: _imports_1,
                                        class: "icon-16",
                                        alt: "Re-send Invite"
                                      })
                                    ], -1)),
                                    _createElementVNode("div", null, _toDisplayString(_ctx.$t("btn.Re_send_Invit")), 1)
                                  ], 2)
                                ]),
                                _: 2
                              }, 1032, ["onClick", "disabled"]),
                              _createVNode(_component_el_dropdown_item, {
                                onClick: 
                          (event) => {
                            _ctx.handleCommand('reset', data, event);
                          }
                        ,
                                disabled: _ctx.clearResultsCls(data)
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", {
                                    class: _normalizeClass(["menu-dropdown flex-center", `${_ctx.clearResultsCls(data) ? 'opa-05' : ''}`])
                                  }, [
                                    _cache[16] || (_cache[16] = _createElementVNode("div", { class: "mr-12 flex-center" }, [
                                      _createElementVNode("img", {
                                        src: _imports_2,
                                        class: "icon-16",
                                        alt: "email_24px"
                                      })
                                    ], -1)),
                                    _createElementVNode("div", null, _toDisplayString(_ctx.$t("btn.Clear_Results")), 1)
                                  ], 2)
                                ]),
                                _: 2
                              }, 1032, ["onClick", "disabled"]),
                              _createVNode(_component_el_dropdown_item, {
                                onClick: ($event: any) => (_ctx.handleCommand('delete', data))
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_29, [
                                    _cache[17] || (_cache[17] = _createElementVNode("div", { class: "mr-12 flex-center" }, [
                                      _createElementVNode("img", {
                                        src: _imports_3,
                                        class: "icon-16",
                                        alt: "delete_20px"
                                      })
                                    ], -1)),
                                    _createElementVNode("div", null, _toDisplayString(_ctx.$t("btn.Delete")), 1)
                                  ])
                                ]),
                                _: 2
                              }, 1032, ["onClick"])
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        default: _withCtx(() => [
                          _cache[18] || (_cache[18] = _createElementVNode("span", { class: "el-dropdown-link" }, [
                            _createElementVNode("div", { class: "image-more-menu flex-center" }, [
                              _createElementVNode("img", {
                                src: _imports_0,
                                alt: "icons_more_vert_black_24dp",
                                class: "icon-16"
                              })
                            ])
                          ], -1))
                        ]),
                        _: 2
                      }, 1024)
                    ])
                  ]),
                  _cache[20] || (_cache[20] = _createElementVNode("td", null, [
                    _createElementVNode("div", { style: {"width":"3.2rem"} })
                  ], -1))
                ], 8, _hoisted_21))
              }), 128))
            ])
          ]),
          (!_ctx.dataFiltered.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_30, " No records maches your search or filter "))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_31, [
      _createVNode(_component_pagination, {
        totalData: _ctx.dataFiltered.length,
        currentPage: _ctx.page,
        onPageChange: _ctx.handlePageChange
      }, null, 8, ["totalData", "currentPage", "onPageChange"])
    ])
  ], 64))
}