
import { defineComponent } from "vue";
import { ElMessageBox, ElNotification } from "element-plus";
import CustomSelect from "@/components/Input/SelectBox.vue";
import helpers from "@/helpers/global";
import _ from "lodash";

export default defineComponent({
  name: "MyAccount",
  components: {
    CustomSelect,
  },
  async created() {
    // @see src\views\MyAccount.vue
    // await this.$store.dispatch("user/loadCurrentUser", null, true);

    // Add Omise.js
    const script = document.createElement("script");
    script.setAttribute("src", "https://cdn.omise.co/omise.js");
    document.body.appendChild(script);
  },
  computed: {
    paymentEnabled() {
      return process.env.VUE_APP_PAYMENT_ENABLED === "on";
    },
    totalCredit() {
      return 11 + " credit remaining";
    },
    user(): any {
      return this.$store.getters["user/user"];
    },
    quota(): any {
      return this.$store.getters["quota/data"];
    },
    buyCreditLabel() {
      // const lang = (this as any).$i18n.getLocale(0) || "en";
      // const price = lang == "en" ? "US$99" : "THB 3,500";
      let price = (this as any).currency == "USD" ? "US$99" : "THB 3,500";

      const email = _.get(this.user, "email", "");
      if (/happily\.ai$/.test(email)) {
        price = (this as any).currency == "USD" ? "US$1" : "THB 35";
      }

      return helpers.t("myAccount.planAndBill.Buy_credit_n", { price: price });
    },
  },
  watch: {
    quota(value: any) {
      if (value) {
        this.updateQuotaDisplay();
      }
    },
    paymentError(value) {
      if (value) {
        console.log(value, "payment error");
      }
    },
    // @fix if url is changed from credit menu
    tabParam(value) {
      if (!value) return;
      switch (value) {
        case "my-profile":
          this.activeTab = "My_Profile";
          break;
        case "my-company":
          this.activeTab = "My_Company";
          break;
        case "plan-billing":
          this.activeTab = "Plan_Billing";
          break;
        case "refer-a-friend":
          this.activeTab = "Refer_a_friend";
          break;
      }
    },
  },
  async mounted() {
    if (!this.$store.getters["user/user"]) {
      await this.$store.dispatch("user/loadCurrentUser");
    }

    const user = this.$store.getters["user/user"];
    if (user) {
      this.color = user.color;
    }

    let team = null;
    if (user && user.teamId) {
      await this.$store.dispatch(
        "team/getTeam",
        { teamId: user.teamId },
        { root: true }
      );
      team = this.$store.getters["team/team"];
      if (team) {
        this.color = team.color;
      }
    }

    await this.$store.dispatch("quota/load", this.user, { root: true });
    this.updateQuotaDisplay();

    // const currencyRef = this.$refs["currencyOptions"] as any;
    // const modalRef = this.$refs["payAsYouGoModalModal"] as any;
    // console.log(currencyRef, "currencyRef");
    // console.log(modalRef, "modalRef");

    // Plan_Billing

    // Omise
    // @see https://www.omise.co/omise-js#using-javascript
    /*
    let OmiseCard: any;
    for (const k in window) {
      if (k == "OmiseCard") {
        OmiseCard = window[k];
      }
    }
    //*/

    // By HTML
    /*
    const form = document.getElementById("checkoutForm");
    const script = document.createElement("script");
    script.setAttribute("src", "https://cdn.omise.co/omise.js");
    script.setAttribute("data-key", "pkey_test_5tsy56mhmsfzmnhhckw");
    script.setAttribute("data-amount", "099");
    // script.setAttribute("data-currency", "THB");
    script.setAttribute("data-currency", "USD");
    script.setAttribute("payment-methods", "credit_card,promptpay");
    script.setAttribute("data-default-payment-method", "credit_card");
    form && form.appendChild(script);
    //*/

    // By Javascript
    /*
    if (OmiseCard) {
      const amount = 199;
      const currency = "USD";
      const description = "Pay as you go";
      OmiseCard.configure({
        publicKey: "pkey_test_5tsy56mhmsfzmnhhckw",
      });
      const button = document.querySelector("#checkoutButton");
      const form: any = document.querySelector("#checkoutForm");
      if (button) {
        button.addEventListener("click", (event) => {
          event.preventDefault();
          OmiseCard.open({
            amount: amount,
            currency: currency,
            defaultPaymentMethod: "credit_card",
            onCreateTokenSuccess: (nonce: string) => {
              if (!form) return;
              if (nonce.startsWith("tokn_")) {
                form.omiseToken.value = nonce;
              } else {
                form.omiseSource.value = nonce;
              }
              form.amount.value = amount;
              form.currency.value = currency;
              form.description.value = description;
              // console.log(form, "form");
              form.submit();
            },
          });
        });
      }
    }
    //*/
  },
  data() {
    return {
      activeTab: "My_Profile", // My_Profile, My_Company, Plan_Billing
      yourPlan: "Free Plan",
      isUnlimited: false,
      credit: 0,
      creditRemaining: 5,
      creditTotal: 5,
      percentage: 0,
      couponCode: "",
      redeemCouponFailed: false,
      userDetail: {
        id: "",
        firstname: "",
        lastname: "",
        company_name: "",
        company_color: "#000",
        logo: "",
        // link of image
        company_logo: "",
        imageFile: "",
      },
      color: "",
      monthlySubscriptionModalStatus: false,
      payAsYouGoModalStatus: false,
      // couponModalStatus: true,
      listItemPay: [
        {
          name: "Unlimited_assessment_variations",
        },
        {
          name: "Access_to_all_available",
        },
        {
          name: "Group_analytics",
        },
        {
          name: "Bulk_invites",
        },
        {
          name: "Custom_branding",
        },
        {
          name: "Email_Support",
        },
      ],
      // currency: "USD", // THB
      currency: "THB",
      currencySelected: {
        key: "THB",
        label: "Thai Baht - THB",
      },
      currencyOptions: [
        { key: "THB", label: "Thai Baht - THB" },
        { key: "USD", label: "U.S. Dollar - USD" },
      ],
      avg_10_credits: 35 as any,
      avg_20_credits: "31.50" as any,
      avg_50_credits: 24.5 as any,
      sum_10_credits: 350 as any,
      sum_20_credits: 630 as any,
      sum_50_credits: 1225 as any,
    };
  },
  methods: {
    handleCurrency(newValue: any) {
      this.currencySelected = newValue;
      this.currency = newValue.key;
      switch (this.currency) {
        case "THB":
          this.avg_10_credits = 35;
          this.avg_20_credits = "31.50";
          this.avg_50_credits = 24.5;
          this.sum_10_credits = 350;
          this.sum_20_credits = 630;
          this.sum_50_credits = 1225;
          break;
        case "USD":
          this.avg_10_credits = 1;
          this.avg_20_credits = "0.9";
          this.avg_50_credits = "0.7";
          this.sum_10_credits = 10;
          this.sum_20_credits = 18;
          this.sum_50_credits = 35;
          break;
      }
    },
    openReferAFriendModdal() {
      // @see https://trello.com/c/CpgNhSFa/1544-plans-billing-as-a-admin-i-want-to-be-redirected-to-the-refer-a-friend-tab-while-payments-are-not-working-so-that-i-can-continue
      const $t = (this as any).$t;
      const prefix = "myAccount.paymentInteruptPopup.";
      const title = $t(prefix + "title");
      const body = $t(prefix + "body");
      ElMessageBox.confirm(body, title, {
        confirmButtonText: $t(prefix + "confirmButtonText"),
        cancelButtonText: $t(prefix + "cancelButtonText"),
        // type: "info",
      }).then(() => {
        // this.activeTab = "Refer_a_friend";
        this.$router.push({
          name: "MyAccountWithTab",
          params: { tab: "refer-a-friend" },
        });
      });
    },
    openpayAsYouGoModalStatusDetail() {
      // console.log("openpayAsYouGoModalStatusDetail()");
      // this.payAsYouGoModalStatus = true;
      // this.couponModalStatus = true;
      if (this.paymentEnabled) {
        this.payAsYouGoModalStatus = true;
      } else {
        this.openReferAFriendModdal();
      }
    },
    openPayUnlimitedModalStatusDetail() {
      // console.log("openPayUnlimitedModalStatusDetail()");
      if (!this.paymentEnabled) {
        this.openReferAFriendModdal();
        return;
      }
      this.payAsUnlimited();
    },
    openmonthlySubscriptionModalStatusDetail() {
      this.monthlySubscriptionModalStatus = true;
    },
    async openOmiseModal(req: {
      sku: string;
      price: number;
      callback: () => Promise<string>;
    }) {
      // console.log("openOmiseModal()");
      // console.log("debug...");
      // if (req.sku) return;
      // Omise
      // @see https://www.omise.co/omise-js#using-javascript
      let OmiseCard: any;
      for (const k in window) {
        if (k == "OmiseCard") {
          OmiseCard = window[k];
        }
      }
      if (!OmiseCard) {
        console.log("Error: Undefined OmiseCard");
        return;
      }

      const amount = req.price;
      // const currency = helpers.getCurrency();
      const currency = this.currency;
      const description = helpers.getDescriptionBySku(req.sku, currency);
      // console.log(description, "description");
      OmiseCard.configure({
        publicKey: process.env.VUE_APP_OMISE_PUBLIC_KEY,
      });

      // let Omise: any;
      // for (const k in window) {
      //   if (k == "Omise") {
      //     Omise = window[k];
      //   }
      // }
      // Omise.createToken(
      //   "card",
      //   {
      //     expiration_month: 2,
      //     expiration_year: 2022,
      //     name: "Somchai Prasert",
      //     number: "4242424242424242",
      //     security_code: "123",
      //     street1: "476 Fifth Avenue",
      //     city: "New York",
      //     state: "NY",
      //     postal_code: "10320",
      //     country: "US",
      //   },
      //   (statusCode: any, response: any) => {
      //     console.log(statusCode);
      //     console.log(response);
      //   }
      // );

      // if (OmiseCard) return;

      // console.log("OmiseCard.open()");
      OmiseCard.open({
        amount: amount,
        currency: currency,
        defaultPaymentMethod: "credit_card",
        onCreateTokenSuccess: (nonce: string) => {
          return (async () => {
            const form: any = document.querySelector("#checkoutForm");
            form.action = process.env.VUE_APP_OMISE_API_URL + "/omise/charge";
            if (!form) return;
            if (nonce.startsWith("tokn_")) {
              form.omiseToken.value = nonce;
            } else {
              form.omiseSource.value = nonce;
            }
            form.amount.value = amount;
            form.currency.value = currency;
            form.description.value = description;

            // id of reference
            this.$store.commit("payment/loading", true, { root: true });
            const id = await req.callback();
            form.id.value = id;

            form.redirectUri.value = window.location.href;
            // console.log(form.redirectUri.value, "form.redirectUri.value ");

            form.submit();
          })();
        },
      });
    },
    async payAsYouGo(sku: string) {
      console.log(`payAsYouGo(${sku})`);

      this.yourPlan = "Pay as you go";
      this.payAsYouGoModalStatus = false;

      const callback = async (): Promise<string> => {
        const request = {
          sku: sku,
        };
        // this.$store.commit("payment/loading", true, { root: true });
        await this.$store.dispatch("payment/payAsYouGo", request, {
          root: true,
        });
        // this.$store.commit("payment/loading", false, { root: true });
        const lastInsertId = this.$store.getters["payment/lastInsertId"];
        return lastInsertId;
      };

      const price = helpers.getPriceBySku(sku, "omise");
      this.openOmiseModal({
        sku: sku,
        price: price,
        callback: callback,
      });
    },
    async payAsUnlimited() {
      // console.log("payAsUnlimited()");
      if (this.isUnlimited) return;
      const sku = "Unlimited" + "_" + this.currency;

      const callback = async (): Promise<string> => {
        const request = {
          sku: sku,
        };
        // this.$store.commit("payment/loading", true, { root: true });
        await this.$store.dispatch("payment/payAsUnlimited", request, {
          root: true,
        });
        // this.$store.commit("payment/loading", false, { root: true });
        const lastInsertId = this.$store.getters["payment/lastInsertId"];
        return lastInsertId;
      };

      let price = helpers.getPriceBySku(sku, "omise");

      // @fixed test real credit price
      const email = _.get(this.user, "email", "");
      if (/happily\.ai$/.test(email)) {
        price = (this as any).currency == "USD" ? 100 : 3500;
      }

      this.openOmiseModal({
        sku: sku,
        price: price,
        callback: callback,
      });
    },
    async cancelSubscribe() {
      // console.log("cancelSubscribe()");
      await this.$store.dispatch("payment/cancelSubscription", null, {
        root: true,
      });
      // this.yourPlan = "Pay as you go";
    },
    subscribeRecuring(sku: string, success?: any) {
      // @todo modal for annual: 25% discount
      // const sku = "MONTHLY_1_4900";

      const callback = async (): Promise<string> => {
        if (this.quota) {
          const plan = this.quota.plan;
          if (plan == "monthly" || plan == "annual") {
            await this.cancelSubscribe();
          }
        }
        const request = {
          sku: sku,
        };
        await this.$store.dispatch("payment/payMonthly", request, {
          root: true,
        });
        const lastInsertId = this.$store.getters["payment/lastInsertId"];
        if (typeof success == "function") {
          success();
        }
        return lastInsertId;
      };

      const price = helpers.getPriceBySku(sku, "omise");
      this.openOmiseModal({
        sku: sku,
        price: price,
        callback: callback,
      });
    },
    subscribeMonthly() {
      // console.log("subscribeMonthly()");
      this.monthlySubscriptionModalStatus = false;
      const sku = "MONTHLY_1_4900";
      this.subscribeRecuring(sku, () => {
        this.yourPlan = "Monthly Subscription";
      });
    },
    subscribeAnnual() {
      // console.log("subscribeAnnual()");
      this.monthlySubscriptionModalStatus = false;
      const sku = "YEARLY_1_44100";
      this.subscribeRecuring(sku, () => {
        this.yourPlan = "Annual Subscription";
      });
    },
    async redeemCoupon() {
      this.redeemCouponFailed = false;
      if (this.couponCode) {
        await this.$store.dispatch("coupon/redeem", this.couponCode, {
          root: true,
        });
        const data = this.$store.getters["coupon/data"];
        const credit = data ? Number(data.credit) : 0;

        if (this.$store.getters["coupon/error"]) {
          this.redeemCouponFailed = true;
          ElNotification({
            title: (this as any).$t("myAccount.planAndBill.Failed_redeem"),
            // message: (this as any).$t("coupon.error_messages.message_1"),
            message: this.$store.getters["coupon/error"],
            type: "error",
          });
        } else {
          this.couponCode = "";
          const title = `${(this as any).$t(
            "myAccount.planAndBill.credits_redeemed_1"
          )} ${credit} ${(this as any).$t(
            "myAccount.planAndBill.credits_redeemed_2"
          )}`;
          ElNotification({
            title: title,
            message: (this as any).$t(
              "myAccount.planAndBill.You_have_successfully"
            ),
            type: "success",
          });
        }
      } else {
        // TODO: show error message
        this.redeemCouponFailed = true;
        ElNotification({
          title: (this as any).$t("myAccount.planAndBill.Failed_redeem"),
          message: (this as any).$t("coupon.error_messages.message_1"),
          type: "error",
        });
      }
    },
    updateQuotaDisplay() {
      // console.log("updateQuotaDisplay()");
      const value = this.quota;
      if (value) {
        switch (value.plan) {
          case "payg":
            this.yourPlan = "Pay as you go";
            break;
          case "monthly":
            this.yourPlan = "Monthly Subscription";
            break;
          case "annual":
            this.yourPlan = "Annual Subscription";
            break;
          case "unlimited":
            this.yourPlan = "Unlimited";
            this.isUnlimited = true;
            break;
          case "free":
          default:
            this.yourPlan = "Free Plan";
            break;
        }

        this.credit = value.quota_used;
        this.creditRemaining = value.quota_remaining;
        this.creditTotal = value.quota_total;

        // percentage
        this.percentage = 100;
        if (value.plan != "monthly" && value.plan != "annual") {
          this.percentage = Math.round(
            (value.quota_remaining / value.quota_total) * 100
          );
        }
      } else {
        this.yourPlan = "Free Plan";
        this.percentage = 0;
      }
    },
  },
});
